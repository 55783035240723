import React from "react";
// import Breadcrumb from "../components/Breadcrumb";
import FooterOne from "../components/Footer/FooterTwo";
import NavBar from "../components/Header/NavbarTwo";
// import PricingAreaOne from "../components/PricingAreaOne";
// import ServiceAreaGroup from "../components/ServiceAreaGroup";
import CareerAreaOne from "../components/Career/CareerAreaOne";
import { Helmet } from "react-helmet";


import CounterAreaThree from "../components/Counter/CounterAreaThree";
import SliderDetail from "../components/Service/SliderDetail";
import ServicesFaqs from "../components/Faqs/ServicesFaqs";

const Service = () => {
  const schemaMarkup = `
  {
    "@context": "http://schema.org",
    "@type": "service",
    "name": "Services",
    "description": "Explore our comprehensive services, including Managed IT, Marketing, Revenue Cycle Management, and IT Help Desk support. Empower your business with expert solutions.",
    "url": "https://techmatterglobal.com/service/",
    "provider": {
      "@type": "Organization",
      "name": "TechMatter (Pvt) Ltd",
      "url": "https://techmatterglobal.com/",
      "logo": "https://techmatterglobal.com/static/media/techmatter-logo.18ba9962b358c5d14134.webp",
      "description": "TechMatter (Pvt) Ltd is a leading Top-tier IT managed services provider. Specializing in security, helpdesk, and comprehensive IT support solutions.",
      "telephone": "(021) 37447307",
      "email": "info@techmatterglobal.com",
      "sameAs": [
        "https://www.linkedin.com/company/techmatter-pvt-ltd/",
        "https://twitter.com/techmatterpvt",
        "https://www.facebook.com/techmatterglobal/",
        "https://www.instagram.com/techmatterglobal/"
      ],
      "address": {
        "@type": "PostalAddress",
        "streetAddress": "194-N, Block 2 PECHS",
        "addressLocality": "Karachi",
        "addressRegion": "Sindh",
        "addressCountry": "Pakistan"
      }
    }
  }
  `;
  return (
    <>
      <Helmet>
        <meta name="title" content="Expert Managed IT Services | Healthcare RCM Services - TechMatter (PVT) Ltd" />

        <meta name="description" content="Explore our comprehensive services, including Managed IT, Marketing, Revenue Cycle Management, and IT Help Desk support. Empower your business with expert solutions." />

        <meta name="keywords" content="Managed IT Services For Small Business, RCM Services" />

        <link rel="canonical" href="https://techmatterglobal.com/service" />
        <title>Expert Managed IT Services | Healthcare RCM Services - TechMatter (PVT) Ltd</title>
        <script type="application/ld+json">{schemaMarkup}</script>

        {/* <script type="application/ld+json">{schemaMarkup}</script> */}
      </Helmet>
      {/* Navigation Bar */}
      <NavBar />

      {/* Navigation Bar */}
      {/* <Breadcrumb title={"Services"} /> */}
      <SliderDetail />

      {/* Service Area One */}
      {/* <ServiceAreaGroup /> */}

      {/* Career Area One */}
      <CareerAreaOne />

      {/* Pricing Area One */}
      {/* <PricingAreaOne /> */}

      {/* FAQ Area One */}
      <ServicesFaqs />

      <CounterAreaThree />

      {/* Footer One */}
      <FooterOne />
    </>
  );
};

export default Service;
