import React from "react";
import AboutAreaOne from "../components/About/AboutAreaOne";
import CounterAreaOne from "../components/Counter/CounterAreaThree";
import FooterOne from "../components/Footer/FooterTwo";
import NavBar from "../components/Header/NavbarTwo";
import { Helmet } from "react-helmet";

const About = () => {

  const schemaMarkup = `
  [{
    "@context": "http://schema.org",
    "@type": "AboutPage",
    "name": "About",
    "description": "Unlock seamless business operations with our expert Managed IT Services Company. From managed equipment services to comprehensive IT solutions",
    "url": "https://techmatterglobal.com/about/",
    "mainEntity": {
      "@type": "Organization",
      "name": "TechMatter (Pvt) Ltd",
      "url": "https://techmatterglobal.com/",
      "logo": "https://techmatterglobal.com/static/media/techmatter-logo.18ba9962b358c5d14134.webp",
      "description": "TechMatter (Pvt) Ltd is a leading Top-tier IT managed services provider. Specializing in security, helpdesk, and comprehensive IT support solutions.",
      "telephone": "(021) 37447307",
      "email": "info@techmatterglobal.com",
      "sameAs": [
        "https://www.linkedin.com/company/techmatter-pvt-ltd/",
        "https://twitter.com/techmatterpvt",
        "https://www.facebook.com/techmatterglobal/",
        "https://www.instagram.com/techmatterglobal/"
      ],
      "address": {
        "@type": "PostalAddress",
        "streetAddress": "194-N, Block 2 PECHS",
        "addressLocality": "Karachi",
        "addressRegion": "Sindh",
        "addressCountry": "Pakistan"
      }
    }
  }]`;

  return (
    <>
      <Helmet>
        <meta
          name="title"
          content="Managed IT Services for Small Businesses - TechMatter (PVT) Ltd"
        />
        <meta
          name="description"
          content="Unlock seamless business operations with our expert Managed IT Services Company. From managed equipment services to comprehensive IT solutions. "
        />
        <meta
          name="keywords"
          content="Managed IT Services Company, managed equipment services, Managed IT Services Provider"
        />
        <link rel="canonical" href="https://techmatterglobal.com/about" />
        <title>Managed IT Services for Small Businesses - TechMatter (PVT) Ltd</title>
        <script type="application/ld+json">
          {schemaMarkup}
        </script>
      </Helmet>
      <NavBar />
      <AboutAreaOne />
      <CounterAreaOne />
      <FooterOne />
    </>
  );
};

export default About;
