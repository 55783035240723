import React from "react";
import FooterOne from "../components/Footer/FooterTwo";
import NavBar from "../components/Header/NavbarTwo";
import MarketingDetail from "../components/Service/ServiceList/MarketingDetail";
import CounterAreaThree from "../components/Counter/CounterAreaThree";
import { Helmet } from "react-helmet";

function Marketing() {
  const schemaMarkup = `
  {
    "@context": "http://schema.org",
    "@type": "service",
    "name": "Marketing Services",
    "description": "Elevate your online presence and reach your target audience effectively with our expert marketing strategies. Let us tailor solutions to propel your business forward.",
    "url": "https://techmatterglobal.com/marketing/",
    "provider": {
      "@type": "Organization",
      "name": "TechMatter (Pvt) Ltd",
      "url": "https://techmatterglobal.com/",
      "logo": "https://techmatterglobal.com/static/media/techmatter-logo.18ba9962b358c5d14134.webp",
      "description": "TechMatter (Pvt) Ltd is a leading Top-tier IT managed services provider. Specializing in security, helpdesk, and comprehensive IT support solutions.",
      "telephone": "(021) 37447307",
      "email": "info@techmatterglobal.com",
      "sameAs": [
        "https://www.linkedin.com/company/techmatter-pvt-ltd/",
        "https://twitter.com/techmatterpvt",
        "https://www.facebook.com/techmatterglobal/",
        "https://www.instagram.com/techmatterglobal/"
      ],
      "address": {
        "@type": "PostalAddress",
        "streetAddress": "194-N, Block 2 PECHS",
        "addressLocality": "Karachi",
        "addressRegion": "Sindh",
        "addressCountry": "Pakistan"
      }
    }
  }
  `;

  return (
    <>
      <Helmet>
        <meta
          name="title"
          content="Online Digital Marketing Services | Affordable Marketing Services - TechMatter (PVT) Ltd"
        />

        <meta
          name="description"
          content="Elevate your online presence and reach your target audience effectively with our expert marketing strategies. Let us tailor solutions to propel your business forward. "
        />

        <meta
          name="keywords"
          content="Premier digital marketing Services, Professional Marketing Services  "
        />
        <title>
          Online Digital Marketing Services | Affordable Marketing Services - TechMatter (PVT) Ltd
        </title>
        <link rel="canonical" href="https://techmatterglobal.com/marketing" />
        <script type="application/ld+json">{schemaMarkup}</script>
      </Helmet>
      <NavBar />
      <MarketingDetail />
      <CounterAreaThree />
      <FooterOne />
    </>
  );
}

export default Marketing;
