import React from "react";
import MarketingOne from "./Images/healthcare-staff-augmentation-one.webp";
import MarketingTwo from "./Images/healthcare-staff-augmentation-two.webp";
import MarketingThree from "./Images/healthcare-staff-augmentation-three.webp";
import TickImage from "./Images/tick.svg";
import MarketingMainBanner from "./Images/MarketingMainBannerwep.webp";

function MarketingDetail() {
  return (
    <>
      {/* ===================== Service Details Area start ===================== */}
      <div
        className="breadcrumb-area bg-cover"
        style={{ backgroundImage: `url(${MarketingMainBanner})` }}
      >
        <div className="container">
          <div className="breadcrumb-inner">
            <div className="row justify-content-center">
              <div className="col-lg-12">
                <h1 className="page-title">Marketing</h1>
              </div>
              <div className="col-lg-6 text-lg-end">
                {/* <ul className='page-list'>
                  <li>
                    <Link to='/'>Home</Link>
                  </li>
                  {"  "}/ <li>{title}</li>
                </ul> */}
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Section No 01 */}
      <div className="about-area bg-gradient-gray pd-top-60">
        <div className="container">
          <div className="row">
            <div
              className="col-lg-6 "
              data-aos="fade-right"
              data-aos-delay="100"
              data-aos-duration="1500"
            >
              <div className="about-thumb-inner mb-4 mb-lg-0">
                <img
                  className="main-img"
                  src={MarketingOne}
                  alt="MarketingOne"
                />
              </div>
            </div>
            <div
              className="col-lg-6 wow animated fadeInRight"
              data-aos="fade-left"
              data-aos-delay="100"
              data-aos-duration="1500"
            >
              <div className="section-title mb-0">
                <h2 className="title managed-it-title">
                  Pull Off Your <span> Marketing Goals </span> with Tech Matter
                </h2>
                <p className="content mb-4">
                  Welcome to Tech Matter, your premier partner in Digital
                  Marketing. Our tailored strategies amplify your brand, boost
                  conversions, and drive growth. We craft precise marketing
                  plans that align with your business objectives.
                </p>

                <p className="content">
                  We use market trends, consumer insights, and competitor
                  analysis to create a roadmap that maximizes your online
                  potential. Our SEO expertise helps enhance online visibility
                  and drive traffic to marketing platforms. We optimize product
                  pages, improve site architecture, and employ the latest SEO
                  techniques to make you easily discoverable.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Section No 02 */}
      <div className="faq-area pd-bottom-60">
        <div className="container">
          <div className="row pd-top-120">
            <div className="col-xl-7 col-lg-6 col-md-8 order-lg-last">
              <div className="about-thumb-inner video-thumb">
                <img
                  className="main-img"
                  src={MarketingTwo}
                  alt="MarketingTwo"
                />
              </div>
            </div>
            <div className="col-xl-5 col-lg-6 align-self-center">
              <div className="section-title mb-0 mt-4 mt-lg-0">
                <h2 className="title managed-it-title">
                  Capture More <span> Revenue </span> with Full-funnel{" "}
                  <span> Marketing </span> Services:
                </h2>
                <p>
                  Our team of experts can assist you in achieving your business
                  objectives by developing and managing various digital
                  advertising and marketing campaigns.
                </p>
              </div>
              <div
                className="accordion accordion-inner style-2 accordion-icon-left mt-3"
                id="accordionExample"
              >
                <div className="accordion-item">
                  <h2 className="accordion-header" id="headingOne">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseOne"
                      aria-expanded="false"
                      aria-controls="collapseOne"
                    >
                      What services does Tech Matter offer?{" "}
                    </button>
                  </h2>
                  <div
                    id="collapseOne"
                    className="accordion-collapse collapse" //show
                    aria-labelledby="headingOne"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body">
                      Tech Matter provides digital marketing services tailored
                      to companies’ specific needs. Our services include SEO
                      (search engine optimization), web development,
                      pay-per-click (PPC) advertising, social media marketing,
                      content marketing, online reputation management, and
                      full-funnel marketing strategies.
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header" id="headingTwo">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseTwo"
                      aria-expanded="false"
                      aria-controls="collapseTwo"
                    >
                      How does Tech Matter approach digital marketing strategy
                      for companies?
                    </button>
                  </h2>
                  <div
                    id="collapseTwo"
                    className="accordion-collapse collapse"
                    aria-labelledby="headingTwo"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body">
                      At Tech Matter, we analyze your industry, audience,
                      competition, and marketing efforts to create a customized
                      digital marketing strategy that drives measurable results
                      for your company.
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header" id="headingThree">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseThree"
                      aria-expanded="false"
                      aria-controls="collapseThree"
                    >
                      What SEO services does Tech Matter offer?
                    </button>
                  </h2>
                  <div
                    id="collapseThree"
                    className="accordion-collapse collapse"
                    aria-labelledby="headingThree"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body">
                      Tech Matter offers SEO services to organizations to
                      improve their online visibility and search rankings.
                      Services include keyword research, on-page optimization,
                      technical SEO audits, content creation and optimization,
                      link building, local SEO, and performance tracking.
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Section No 03 */}
      <div className="price-process-area pd-top-60 pd-bottom-60  mg-top-60 ">
        <div className="contact-inner-1 contact-inner-2">
          <div className="row">
            <div
              className="col-lg-6 "
              data-aos="fade-right"
              data-aos-delay="200"
              data-aos-duration="1500"
            >
              <img
                className="w-100"
                src={MarketingThree}
                alt="MarketingThree"
              />
            </div>
            <div
              className="col-lg-4"
              data-aos="fade-left"
              data-aos-delay="100"
              data-aos-duration="1500"
            >
              <div className="section-title mb-0">
                <h2 className="title mb-4 managed-it-title">
                  Help Your <span> Business </span> Scale to New Heights
                </h2>
                <p>Some key benefits of Digital Marketing Services include:</p>
                <div className="media mb-3">
                  <div className="media-left me-3">
                    <img src={TickImage} alt="Tick Icon" />
                  </div>
                  <div className="media-body mg-top-20">
                    <h5>Access to Industry Insights and Trends.</h5>
                  </div>
                </div>
                <div className="media mb-3">
                  <div className="media-left me-3">
                    <img src={TickImage} alt="Tick Icon" />
                  </div>
                  <div className="media-body mg-top-20">
                    <h5> Measurable Results and ROI. </h5>
                  </div>
                </div>
                <div className="media mb-3">
                  <div className="media-left me-3">
                    <img src={TickImage} alt="Tick Icon" />
                  </div>
                  <div className="media-body mg-top-20">
                    <h5> Adaptability to Changing Trends. </h5>
                  </div>
                </div>
                <div className="media mb-3">
                  <div className="media-left me-3">
                    <img src={TickImage} alt="Tick Icon" />
                  </div>
                  <div className="media-body mg-top-20">
                    <h5> Best SEO Practices. </h5>
                  </div>
                </div>
                <div className="media">
                  <div className="media-left me-3">
                    <img src={TickImage} alt="Tick Icon" />
                  </div>
                  <div className="media-body mg-top-20">
                    <h5> Enhanced Social Media Reach. </h5>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* ===================== Service Details Area End ===================== */}
    </>
  );
}

export default MarketingDetail;
