import React, { useState } from "react";
import ManagedItServicesImageOne from "./Images/manage-it-01.webp";
import ManagedItServicesImageTwo from "./Images/manage-it-02.webp";
import ManagedItServicesImageThree from "./Images/manage-it-03.webp";
import TickImage from "./Images/tick.svg";
import ManagedItServicesImageMainBanner from "./Images/managedITServicesMainBanner.webp";

function ManagnedItService() {
  return (
    <>
      {/* ===================== Service Details Area start ===================== */}
      <div
        className="breadcrumb-area bg-cover"
        style={{ backgroundImage: `url(${ManagedItServicesImageMainBanner})` }}
      >
        <div className="container">
          <div className="breadcrumb-inner">
            <div className="row justify-content-center">
              <div className="col-lg-12">
                <h1 className="page-title">Managed IT Services</h1>
              </div>
              <div className="col-lg-6 text-lg-end">
                {/* <ul className='page-list'>
                  <li>
                    <Link to='/'>Home</Link>
                  </li>
                  {"  "}/ <li>{title}</li>
                </ul> */}
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Section No 01 */}
      <div className="about-area bg-gradient-gray pd-top-60">
        <div className="container">
          <div className="row">
            <div
              className="col-lg-6 "
              data-aos="fade-right"
              data-aos-delay="100"
              data-aos-duration="1500"
            >
              <div className="about-thumb-inner mb-4 mb-lg-0">
                <img
                  className="main-img"
                  src={ManagedItServicesImageOne}
                  alt="ManagedItServicesImageOne"
                />
              </div>
            </div>
            <div
              className="col-lg-6 wow animated fadeInRight"
              data-aos="fade-left"
              data-aos-delay="100"
              data-aos-duration="1500"
            >
              <div className="section-title mb-0">
                {/* <h6 className="sub-title">ABOUT US</h6> */}
                <h2 className="title managed-it-title">
                  Drive Measurable <span>Growth</span> with Managed IT Services
                </h2>
                <p className="content mb-4">
                  Managed IT Services can greatly benefit your organization by
                  ensuring employees remain productive, efficient, and focused
                  on meeting customers' needs. Tech Matter offers comprehensive
                  support for your IT environment, regardless of whether you
                  have no IT team, a small team, or a full IT department. We
                  provide the necessary processes and technologies to help you
                  achieve your business goals.
                </p>

                <p className="content">
                  At Tech Matter, we understand that businesses need scalable
                  and flexible IT solutions to achieve sustainable growth and
                  profitability. That's why our team, comprised of talented IT
                  professionals from diverse industries, is available 24/7 to
                  provide full-service support. With our innovative technology
                  solutions, you can confidently expand your business in any
                  direction, knowing that our expertise is always available.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Section No 02 */}
      <div className="faq-area pd-bottom-60">
        <div className="container">
          <div className="row pd-top-120">
            <div className="col-xl-7 col-lg-6 col-md-8 order-lg-last">
              <div className="about-thumb-inner video-thumb">
                <img
                  className="main-img"
                  src={ManagedItServicesImageTwo}
                  alt="ManagedItServicesImageTwo"
                />
              </div>
            </div>
            <div className="col-xl-5 col-lg-6 align-self-center">
              <div className="section-title mb-0 mt-4 mt-lg-0">
                <h2 className="title managed-it-title">
                  Learn More About <span>Customized</span> IT Solutions{" "}
                </h2>
                <p>
                  Our customers are our top priority, and we provide custom IT
                  services and personalized attention to ensure their
                  satisfaction.
                </p>
              </div>
              <div
                className="accordion accordion-inner style-2 accordion-icon-left mt-3"
                id="accordionExample"
              >
                <div className="accordion-item">
                  <h2 className="accordion-header" id="headingOne">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseOne"
                      aria-expanded="false"
                      aria-controls="collapseOne"
                    >
                      How do Managed IT Services providers ensure data security
                      and compliance?
                    </button>
                  </h2>
                  <div
                    id="collapseOne"
                    className="accordion-collapse collapse" //show
                    aria-labelledby="headingOne"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body">
                      We take your data's confidentiality, integrity, and
                      availability seriously, using various security measures
                      and best practices. This includes implementing robust
                      cybersecurity protocols, conducting regular security
                      audits and assessments, and ensuring compliance with
                      relevant industry regulations and standards.
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header" id="headingTwo">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseTwo"
                      aria-expanded="false"
                      aria-controls="collapseTwo"
                    >
                      How does pricing for Managed IT Services work?
                    </button>
                  </h2>
                  <div
                    id="collapseTwo"
                    className="accordion-collapse collapse"
                    aria-labelledby="headingTwo"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body">
                      The cost of Managed IT Services is generally determined by
                      various factors, including the size and intricacy of the
                      IT system, the level of support needed, and the number of
                      users to be assisted. We understand that every business
                      has unique needs and budgets, so we offer tiered pricing
                      plans with varying service levels, providing flexibility
                      to accommodate different financial situations.
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header" id="headingThree">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseThree"
                      aria-expanded="false"
                      aria-controls="collapseThree"
                    >
                      Are Managed IT Services customizable to fit my business
                      needs?
                    </button>
                  </h2>
                  <div
                    id="collapseThree"
                    className="accordion-collapse collapse"
                    aria-labelledby="headingThree"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body">
                      IT Services can be customed according to your business's
                      needs and requirements. We will evaluate your current IT
                      infrastructure.
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Section No 03 */}
      <div className="price-process-area pd-top-60 pd-bottom-60  mg-top-60 ">
        <div className="contact-inner-1 contact-inner-2">
          <div className="row">
            <div
              className="col-lg-6 "
              data-aos="fade-right"
              data-aos-delay="200"
              data-aos-duration="1500"
            >
              <img
                className="w-100"
                src={ManagedItServicesImageThree}
                alt="ManagedItServicesImageThree"
              />
            </div>
            <div
              className="col-lg-4"
              data-aos="fade-left"
              data-aos-delay="100"
              data-aos-duration="1500"
            >
              <div className="section-title mb-0">
                <h2 className="title mb-4 managed-it-title">
                  Benefits of Managed <span>IT</span> Services?
                </h2>
                <p>Some key benefits of Managed IT Services include:</p>
                <div className="media mb-3">
                  <div className="media-left me-3">
                    <img src={TickImage} alt="Tick Icon" />
                  </div>
                  <div className="media-body mg-top-20">
                    <h5> Improved uptime and reliability.</h5>
                  </div>
                </div>
                <div className="media mb-3">
                  <div className="media-left me-3">
                    <img src={TickImage} alt="Tick Icon" />
                  </div>
                  <div className="media-body mg-top-20">
                    <h5> Predictable IT infrastructure costs.</h5>
                  </div>
                </div>
                <div className="media mb-3">
                  <div className="media-left me-3">
                    <img src={TickImage} alt="Tick Icon" />
                  </div>
                  <div className="media-body mg-top-20">
                    <h5> Access to a group of experienced IT experts.</h5>
                  </div>
                </div>
                <div className="media mb-3">
                  <div className="media-left me-3">
                    <img src={TickImage} alt="Tick Icon" />
                  </div>
                  <div className="media-body mg-top-20">
                    <h5> Focus on core business goals </h5>
                  </div>
                </div>
                <div className="media">
                  <div className="media-left me-3">
                    <img src={TickImage} alt="Tick Icon" />
                  </div>
                  <div className="media-body mg-top-20">
                    <h5> Enhanced web cybersecurity.</h5>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* ===================== Service Details Area End ===================== */}
    </>
  );
}

export default ManagnedItService;
