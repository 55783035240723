import React from "react";
import AllCategoriesPage from "../components/blogs/AllCategoriesPage";
import { Helmet } from "react-helmet";

const AllCategories = () => {

  return (
    <>
      <Helmet>
        <meta name="title" content="Latest Blogs Tech & RCM  - TechMatter (PVT) Ltd" />

        <meta
          name="description"
          content="Discover TechMatter's blog, featuring insightful articles, resources, and expert perspectives on IT, RCM marketing, and more services."
        />

        <link rel="canonical" href="https://techmatterglobal.com/blogs" />
        <title> Latest Blogs Tech & RCM  - TechMatter (PVT) Ltd</title>
      </Helmet>
      <AllCategoriesPage />
    </>)
};

export default AllCategories;
