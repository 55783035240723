import React from "react";
import {
  FaArrowRight,
  FaCalendarAlt,
  FaEnvelope,
  FaFacebookF,
  FaInstagram,
  FaPhoneAlt,
  FaLinkedin,
} from "react-icons/fa";
import { RiTwitterXLine } from "react-icons/ri";
import { Link } from "react-router-dom";
import FooterLogo from "./Images/techmatter-logo-white.webp";
import FirstBlog from "./Images/Image-01.png";
import SecondBlog from "./Images/Image-02.png";



const scrollToTop = () => {
  window.scrollTo(0, 0);
};


const FooterOne = () => {

  return (
    <>
      {/* ================== Footer One Start ==================*/}
      <footer className="footer-area bg-black bg-cover ft-bg">
        <div className='work-process-area pd-top-20 pd-bottom-20 work-process-bg bg-dark-bottom'>
          <div className='container'>
            <div className='row'>
              {/* Column No 01 */}
              <div className='col'>
                <div className='thumb'>
                  <Link to="https://www.goodfirms.co/company/tech-matter-global" target="_blank">
                    <img src='assets/img/client-img/google-firms.png' alt='Google Firms' />
                  </Link>
                </div>
              </div>
              {/* Column No 02 */}
              <div className='col'>
                <div className='thumb'>
                  <Link to="https://clutch.co/profile/tech-matter#highlights" target="_blank">
                    <img src='assets/img/client-img/clutch.png' alt='Clutch' />
                  </Link>
                </div>
              </div>
              {/* Column No 03 */}
              <div className='col'>
                <div className='thumb'>
                  <Link to="https://g.page/r/CZ6yLf6rmbELEB0/review" target="_blank">
                    <img src='assets/img/client-img/google-reviews.png' alt='Google Reviews' />
                  </Link>
                </div>
              </div>
              {/* Column No 04 */}
              <div className='col'>
                <div className='thumb'>
                  <Link to="https://www.merchantcircle.com/tech-matter-global-pittsburgh-pa" target="_blank">
                    <img src='assets/img/client-img/merchant-circle.png' alt='Merchant Circle' />
                  </Link>
                </div>
              </div>
              {/* Column No 05 */}
              <div className='col'>
                <div className='thumb'>
                  <Link to="https://www.trustpilot.com/review/techmatterglobal.com" target="_blank">
                    <img src='assets/img/client-img/trust-pilot.png' alt='Trust Pilot' />
                  </Link>
                </div>
              </div>

            </div>
          </div>
        </div>
        <div className="container">
          <div className="row">
            <div className="col-lg-3 col-md-6">
              <div className="widget widget_about">
                <div className="thumb">
                  <img src={FooterLogo} alt="Techmatter Logo" />
                </div>
                <div className="details">
                  <p>
                    IT Solutions and Managed Services Provider specializing in
                    developing custom solutions.
                  </p>
                  <div className="d-flex align-items-center">
                  <p className="mb-0 me-2">
                    <FaPhoneAlt />
                  </p>
                  <div>
                    <p className="mb-0">
                      <a href="tel:+17816675321">+1 (781) 667-5321</a>
                    </p>
                    <p className="mb-0">
                    <a href="tel:+02137447307">(021) 37447307</a>
                    </p>
                  </div>
                </div>
                  <p className="mt-2">
                    <FaEnvelope />{" "}
                    <a href="mailto:info@techmatterglobal.com">
                      info@techmatterglobal.com
                    </a>
                  </p>

                  <ul className="social-media">
                    <li>
                      <a
                        href="https://www.linkedin.com/company/techmatter-pvt-ltd/"
                        target="_blank"
                      >
                        <FaLinkedin />
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://twitter.com/techmatterpvt"
                        target="_blank"
                      >
                        <RiTwitterXLine />
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://www.facebook.com/techmatterglobal/"
                        target="_blank"
                      >
                        <FaFacebookF />
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://www.instagram.com/techmatterglobal/"
                        target="_blank"
                      >
                        <FaInstagram />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6">
              <div className="widget widget_nav_menu">
                <h4 className="widget-title">Our Service</h4>
                <ul>
                  <li>
                    <Link to="/managed-it-services" onClick={scrollToTop}>
                      <FaArrowRight /> Managed IT services
                    </Link>
                  </li>
                  <li>
                    <Link to="/revenue-cycle-management" onClick={scrollToTop}>
                      <FaArrowRight /> Revenue Cycle Management
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/healthcare-staff-augmentation"
                      onClick={scrollToTop}
                    >
                      <FaArrowRight /> Healthcare Staff Augmentation{" "}
                    </Link>
                  </li>
                  <li>
                    <Link to="/electronic-health-record" onClick={scrollToTop}>
                      <FaArrowRight /> Electronic Health Record
                    </Link>
                  </li>
                  <li>
                    <Link to="/enterprise-it" onClick={scrollToTop}>
                      <FaArrowRight /> Enterprise IT
                    </Link>
                  </li>
                  <li>
                    <Link to="/marketing" onClick={scrollToTop}>
                      <FaArrowRight /> Marketing
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-3 col-md-6">
              <div className="widget widget_nav_menu">
                <h4 className="widget-title">Quick Links</h4>
                <ul>
                  <li>
                    <Link to="/about" onClick={scrollToTop}>
                      <FaArrowRight /> About
                    </Link>
                  </li>
                  <li>
                    <Link to="/contact" onClick={scrollToTop}>
                      <FaArrowRight /> Contact
                    </Link>
                  </li>
                  <li>
                    <Link to="/blogs" onClick={scrollToTop}>
                      <FaArrowRight /> Blogs
                    </Link>
                  </li>
                  <li>
                    <Link to="/service" onClick={scrollToTop}>
                      <FaArrowRight /> Service
                    </Link>
                  </li>
                  <li>
                    <Link to="/privacy-policy" onClick={scrollToTop}>
                      <FaArrowRight /> Privacy Policy{" "}
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-3 col-md-6">
              <div className="widget widget-recent-post">
                <h4 className="widget-title">Blogs</h4>
                <ul>
                  <li>
                    <div className="media">
                      <div className="media-left">
                        <img src={FirstBlog} alt="FirstBlog" />
                      </div>
                      <div className="media-body align-self-center">
                        <div className="post-info mb-2">
                          <FaCalendarAlt />
                          <span>July 7, 2023</span>
                        </div>
                        <h6 className="title mb-0">
                          <Link
                            to="/what-is-a-network-prefix-length"
                            onClick={scrollToTop}
                          >
                            What is a network prefix length?
                          </Link>
                        </h6>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div className="media">
                      <div className="media-left">
                        <img src={SecondBlog} alt="SecondBlog" />
                      </div>
                      <div className="media-body align-self-center">
                        <div className="post-info mb-2">
                          <FaCalendarAlt />
                          <span>September 4, 2023</span>
                        </div>
                        <h6 className="title mb-0">
                          <Link
                            to="/what-is-network-stack-in-bios"
                            onClick={scrollToTop}
                          >
                            What is network stack in bios?
                          </Link>
                        </h6>
                      </div>
                    </div>
                  </li>
                </ul>

              </div>
            </div>
          </div>
        </div>
        <div className="footer-bottom">
          <div className="container">
            <div className="row">
              <div className="col-md-6 align-self-center">
                <p>
                  ©{" "}
                  <Link to="/" onClick={scrollToTop}>
                    TechMatter
                  </Link>{" "}
                  2024 | All Rights Reserved
                </p>
              </div>
              {/* <div className="col-md-6 text-lg-end">
              <a href="/privacy-policy">Privacy Policy</a>
              <a href="/contact">Contact Us</a>
              </div> */}
            </div>
          </div>
        </div>
      </footer>
      {/* ================== Footer One  end ==================*/}
    </>
  );
};

export default FooterOne;
