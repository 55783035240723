import React, { useEffect, useState } from "react";
import { useParams, Link } from "react-router-dom";
import { ApolloClient, InMemoryCache, gql } from "@apollo/client";
import blogBanner from "./Images/all-category-blog-wp.webp";
import "./style/categorypage.css";
import NavbarTwo from "../Header/NavbarTwo";
import FooterOne from "../Footer/FooterTwo";


const client = new ApolloClient({
    uri: "https://techmatterglobal.com/blog/graphql",
    cache: new InMemoryCache(),
});

const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString("en-US", {
        year: "numeric",
        month: "long",
        day: "numeric",
    });
};

const CategoryPage = () => {
    const { slug } = useParams();
    const [categories, setCategories] = useState([]);
    const [categoryPosts, setCategoryPosts] = useState([]);
    const [currentCategory, setCurrentCategory] = useState(null);
    const [loading, setLoading] = useState(true);
    const [totalPages, setTotalPages] = useState(1);
    const [currentPage, setCurrentPage] = useState(1);
    const postsPerPage = 2;

    const scrollToTop = () => {
        window.scrollTo(0, 0);
    };

    useEffect(() => {
        setLoading(true);
        client
            .query({
                query: gql`
                    query GetCategories {
                        categories(where: { hideEmpty: false }, first: 50) {
                            nodes {
                                id
                                name
                                slug
                                description
                                posts {
                                    nodes {
                                        id
                                    }
                                }
                            }
                        }
                    }
                `,
            })
            .then((result) => {
                const fetchedCategories = result.data.categories.nodes.map((category) => ({
                    ...category,
                    postCount: category.posts.nodes.length,
                }));

                setCategories(fetchedCategories);

                const currentCategory = fetchedCategories.find(
                    (category) => category.slug === slug
                );
                setCurrentCategory(currentCategory);

                if (!currentCategory) {
                    setLoading(false);

                    return;
                }
                fetchCategoryPosts(slug);
            })
            .catch((error) => {
                console.error("Error fetching categories:", error);
                setLoading(false);
            });
    }, [slug]);

    const fetchCategoryPosts = (slug) => {
        client
            .query({
                query: gql`
                    query GetCategoryPosts($slug: String!) {
                        posts(where: { categoryName: $slug }) {
                            nodes {
                                id
                                title
                                slug
                                date
                                featuredImage {
                                    node {
                                        sourceUrl
                                    }
                                }
                            }
                            pageInfo {
                                hasNextPage
                                endCursor
                            }
                        }
                    }
                `,
                variables: { slug },
            })
            .then((postResult) => {
                const posts = postResult.data.posts.nodes;
                setCategoryPosts(posts);
                setTotalPages(Math.ceil(posts.length / postsPerPage));
                setTimeout(() => setLoading(false), 1000);
            })
            .catch((error) => {
                console.error("Error fetching posts:", error);
                setLoading(false);
            });
    };

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    const currentPosts = categoryPosts.slice(
        (currentPage - 1) * postsPerPage,
        currentPage * postsPerPage
    );

    // Filter out "All" category
    const filteredCategories = categories.filter(
        (category) => category.name !== "All" && category.name !== "Uncategorized"
    );
    if (!loading && !currentCategory) {
        return (
            <div className="centered-column-loader">
                <p>Category not found.</p>
            </div>
        );
    }

    return (
        <>
            <NavbarTwo />
            <div>
                <div
                    className="breadcrumb-area bg-cover"
                    style={{ backgroundImage: `url(${blogBanner})` }}
                >
                    <div className="container">
                        <div className="breadcrumb-inner">
                            <div className="row justify-content-center">
                                <div className="col-lg-12">
                                    <h1 className="page-title">{currentCategory?.name || "Loading..."}</h1>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container pd-top-90 pd-bottom-90">
                    <div className="row">
                        <div className="col-md-8">
                            {loading ? (
                                <div className="centered-column-loader">
                                    <div className="spinner"></div>
                                    <p>Loading category details...</p>
                                </div>
                            ) : (
                                <>
                                    <div className="category-description">
                                        {/* <h3>{currentCategory?.name}</h3> */}
                                        <p>{currentCategory?.description}</p>
                                    </div>

                                    <div className="row">
                                        {currentPosts.length > 0 ? (
                                            currentPosts.map((post) => (
                                                <div className="col-md-12 col-lg-12 mb-4" key={post.id}>
                                                    <div className="post-card">
                                                        {post.featuredImage?.node?.sourceUrl && (
                                                            <img
                                                                src={post.featuredImage.node.sourceUrl}
                                                                className="card-img-top"
                                                                alt="Blog Featured"
                                                            />
                                                        )}
                                                        <div className="card-body">
                                                            <h5 className="card-title">
                                                                <Link to={`/blogs/${post.slug}`}>{post.title}</Link>
                                                            </h5>
                                                            <p className="card-text">
                                                                <small className="text-muted">
                                                                    {formatDate(post.date)}
                                                                </small>
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))
                                        ) : (
                                            <p>No posts found for this category.</p>
                                        )}
                                    </div>

                                    {totalPages > 1 && (
                                        <nav aria-label="Page navigation">
                                            <ul className="pagination">
                                                {[...Array(totalPages)].map((_, index) => (
                                                    <li
                                                        key={index}
                                                        className={`page-item ${currentPage === index + 1 ? "active" : ""}`}
                                                    >
                                                        <button
                                                            className="page-link"
                                                            onClick={() => handlePageChange(index + 1)}
                                                        >
                                                            {index + 1}
                                                        </button>
                                                    </li>
                                                ))}
                                            </ul>
                                        </nav>
                                    )}
                                </>
                            )}
                        </div>

                        <div className="col-md-4">
                            <h4>Categories</h4>
                            <div className="list-group">
                                {loading ? (
                                    <p>Loading...</p>
                                ) : filteredCategories.length === 0 ? (
                                    <p>No categories available.</p>
                                ) : (
                                    filteredCategories.map((category) => (
                                        <Link
                                            key={category.id}
                                            to={`/blogs/category/${category.slug}`}
                                            className="list-group-item list-group-item-action"
                                            onClick={scrollToTop}
                                        >
                                            {category.name}{" "}
                                            <span className="badge badge-secondary">
                                                ({category.postCount})
                                            </span>
                                        </Link>
                                    ))
                                )}
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            <FooterOne />
        </>
    );
};

export default CategoryPage;
