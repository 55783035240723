let serviceList = [
  {
    title: "Design",
    des: "Consult, define, and decide is how we approach designing your solution.",
    img: "assets/img/service-icon/1.png",
  },
  {
    title: "Implement",
    des: "We take the stress out of installing and integrating your new system with minimal inconveniences.",
    img: "assets/img/service-icon/2.png",
  },
  {
    title: "Support & NOC",
    des: "Our flexible, scalable support package ensures that your systems are constantly secured and optimized.",
    img: "assets/img/service-icon/3.png",
  },
  {
    title: "Content Marketing",
    des: "Lorem is Ipsum is simply is design iomyi is text Lorem Ipsum is simply is our busi Lorem is Ipsum is",
    img: "assets/img/service-icon/2.png",
  },
  {
    title: "Mobile App Development",
    des: "Lorem is Ipsum is simply is design iomyi is text Lorem Ipsum is simply is our busi Lorem is Ipsum is",
    img: "assets/img/service-icon/1.png",
  },

  {
    title: "E-commerce Solutions",
    des: "Lorem is Ipsum is simply is design iomyi is text Lorem Ipsum is simply is our busi Lorem is Ipsum is",
    img: "assets/img/service-icon/3.png",
  },
];

export default serviceList;
