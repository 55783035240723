import React from "react";
import HealthcareStaffAugmentationOne from './Images/healthcare-staff-augmentation-one.webp'
import HealthcareStaffAugmentationTwo from './Images/healthcare-staff-augmentation-two.webp'
import HealthcareStaffAugmentationThree from './Images/healthcare-staff-augmentation-three.webp'
import TickImage from './Images/tick.svg'
import HealthcareStaffAugmentationMainBanner from "./Images/HealthcareStaffAugmentationMainBannerwep.webp";

function HealthcareStaffAugmentationDetail() {
  return (
    <>
      {/* ===================== Service Details Area start ===================== */}
      <div
        className="breadcrumb-area bg-cover"
        style={{ backgroundImage: `url(${HealthcareStaffAugmentationMainBanner})` }}
      >
        <div className="container">
          <div className="breadcrumb-inner">
            <div className="row justify-content-center">
              <div className="col-lg-12">
                <h1 className="page-title"> Healthcare Staff Augmentation </h1>
              </div>
              <div className="col-lg-6 text-lg-end">
                {/* <ul className='page-list'>
                  <li>
                    <Link to='/'>Home</Link>
                  </li>
                  {"  "}/ <li>{title}</li>
                </ul> */}
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Section No 01 */}
      <div className="about-area bg-gradient-gray pd-top-60">
        <div className="container">
          <div className="row">
            <div
              className="col-lg-6 "
              data-aos="fade-right"
              data-aos-delay="100"
              data-aos-duration="1500"
            >
              <div className="about-thumb-inner mb-4 mb-lg-0">
                <img
                  className="main-img"
                  src={HealthcareStaffAugmentationOne}
                  alt="HealthcareStaffAugmentationOne"
                />
              </div>
            </div>
            <div
              className="col-lg-6 wow animated fadeInRight"
              data-aos="fade-left"
              data-aos-delay="100"
              data-aos-duration="1500"
            >
              <div className="section-title mb-0">
                {/* <h6 className="sub-title">ABOUT US</h6> */}
                <h2 className="title managed-it-title">
                  Welcome to Our <span>Healthcare Staff Augmentation</span>{" "}
                  Services
                </h2>
                <p className="content mb-4">
                  The healthcare industry constantly evolves, and finding
                  qualified and skilled staff is crucial for healthcare
                  facilities. To keep up with patient demands, maintain the
                  quality of care, and comply with regulations, healthcare staff
                  augmentation services are necessary.
                </p>

                <p className="content">
                  Healthcare staff augmentation refers to organizations
                  partnering with external staffing agencies to hire temporary
                  or permanent staff. These roles range from administrative
                  staff and IT professionals to allied health professionals,
                  nurses, and physicians. Staff augmentation is useful for
                  healthcare facilities to adjust their workforce and address
                  staffing shortages quickly.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Section No 02 */}
      <div className="faq-area pd-bottom-60">
        <div className="container">
          <div className="row pd-top-120">
            <div className="col-xl-7 col-lg-6 col-md-8 order-lg-last">
              <div className="about-thumb-inner video-thumb">
                <img
                  className="main-img"
                  src={HealthcareStaffAugmentationTwo}
                  alt="HealthcareStaffAugmentationTwo"
                />
               
              </div>
            </div>
            <div className="col-xl-5 col-lg-6 align-self-center">
              <div className="section-title mb-0 mt-4 mt-lg-0">
                <h2 className="title managed-it-title">
                  Staff Augmentation for Your Diverse <span>Health</span>{" "}
                  Projects
                </h2>
                <p>
                  At Tech Matter, we specialize in providing comprehensive
                  healthcare staff augmentation solutions tailored to our
                  clients' unique needs.
                </p>
              </div>
              <div
                className="accordion accordion-inner style-2 accordion-icon-left mt-3"
                id="accordionExample"
              >
                <div className="accordion-item">
                  <h2 className="accordion-header" id="headingOne">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseOne"
                      aria-expanded="false"
                      aria-controls="collapseOne"
                    >
                      How does healthcare staff augmentation differ from
                      traditional hiring?
                    </button>
                  </h2>
                  <div
                    id="collapseOne"
                    className="accordion-collapse collapse" //show
                    aria-labelledby="headingOne"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body">
                      Healthcare staff augmentation is outsourcing staffing
                      needs to specialized agencies that maintain qualified
                      candidate pools and offer flexible staffing solutions to
                      healthcare facilities.
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header" id="headingTwo">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseTwo"
                      aria-expanded="false"
                      aria-controls="collapseTwo"
                    >
                      What types of healthcare positions can be filled through
                      staff augmentation?
                    </button>
                  </h2>
                  <div
                    id="collapseTwo"
                    className="accordion-collapse collapse"
                    aria-labelledby="headingTwo"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body">
                      Healthcare staff augmentation services offer diverse
                      professionals, including nurses, nurse practitioners,
                      physician assistants, doctors, administrative staff, IT
                      professionals, medical coders, billing specialists, and
                      other allied health professionals. This variety ensures
                      that healthcare organizations find the right fit for their
                      needs.
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header" id="headingThree">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseThree"
                      aria-expanded="false"
                      aria-controls="collapseThree"
                    >
                      How are healthcare staff augmentation services priced?
                    </button>
                  </h2>
                  <div
                    id="collapseThree"
                    className="accordion-collapse collapse"
                    aria-labelledby="headingThree"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body">
                      The cost of healthcare staff augmentation services can
                      vary depending on many factors, such as the length of the
                      placement, the level of expertise required, and the
                      healthcare organization’s specific needs. We offer
                      different pricing models, including fixed fees or
                      customized pricing based on the services provided.
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Section No 03 */}
      <div className="price-process-area pd-top-60 pd-bottom-60  mg-top-60 ">
        <div className="contact-inner-1 contact-inner-2">
          <div className="row">
            <div
              className="col-lg-6 "
              data-aos="fade-right"
              data-aos-delay="200"
              data-aos-duration="1500"
            >
              <img className="w-100" src={HealthcareStaffAugmentationThree} alt="HealthcareStaffAugmentationThree" />
            </div>
            <div
              className="col-lg-4"
              data-aos="fade-left"
              data-aos-delay="100"
              data-aos-duration="1500"
            >
              <div className="section-title mb-0">
                <h2 className="title mb-4 managed-it-title">
                  Benefits of <span>Healthcare Staff Augmentation</span>
                </h2>
                <p>
                  {" "}
                  Some key benefits of Healthcare Staff Augmentation include:
                </p>
                <div className="media mb-3">
                  <div className="media-left me-3">
                    <img src={TickImage} alt="Tick Icon" />
                  </div>
                  <div className="media-body mg-top-20">
                    <h5>
                      {" "}
                      Enabling flexible staffing to meet changing demands for
                      patient care.
                    </h5>
                  </div>
                </div>
                <div className="media mb-3">
                  <div className="media-left me-3">
                  <img src={TickImage} alt="Tick Icon" />
                  </div>
                  <div className="media-body mg-top-20">
                    <h5>
                      {" "}
                      Minimal costs and burdens related to staffing employees.
                    </h5>
                  </div>
                </div>
                <div className="media mb-3">
                  <div className="media-left me-3">
                  <img src={TickImage} alt="Tick Icon" />
                  </div>
                  <div className="media-body mg-top-20">
                    <h5>
                      Extensive networks of qualified healthcare professionals.
                    </h5>
                  </div>
                </div>
                <div className="media mb-3">
                  <div className="media-left me-3">
                  <img src={TickImage} alt="Tick Icon" />
                  </div>
                  <div className="media-body mg-top-20">
                    <h5>
                      {" "}
                      Prevention of burnout among existing healthcare staff.
                    </h5>
                  </div>
                </div>
                <div className="media">
                  <div className="media-left me-3">
                  <img src={TickImage} alt="Tick Icon" />
                  </div>
                  <div className="media-body mg-top-20">
                    <h5> Access to expertise in niche areas of healthcare.</h5>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* ===================== Service Details Area End ===================== */}
    </>
  );
}

export default HealthcareStaffAugmentationDetail;
