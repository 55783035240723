import React from "react";
import RevenueCycleManagmentOne from "./Images/revenue-cycle-management-01.webp";
import RevenueCycleManagmentTwo from "./Images/revenue-cycle-management-02.webp";
import ManagedItServicesImageThree from "./Images/manage-it-03.webp";
import TickImage from "./Images/tick.svg";
import RevenueCycleManagementMainBannerwep from './Images/RevenueCycleManagementMainBannerwep.webp'

function RevenueCycleManagementDetail() {
  return (
    <>
      {/* ===================== Service Details Area start ===================== */}
      <div
        className="breadcrumb-area bg-cover"
        style={{ backgroundImage: `url(${RevenueCycleManagementMainBannerwep})` }}
      >
        <div className="container">
          <div className="breadcrumb-inner">
            <div className="row justify-content-center">
              <div className="col-lg-12">
                <h1 className="page-title">Revenue Cycle Management</h1>
              </div>
              <div className="col-lg-6 text-lg-end">
                {/* <ul className='page-list'>
                  <li>
                    <Link to='/'>Home</Link>
                  </li>
                  {"  "}/ <li>{title}</li>
                </ul> */}
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Section No 01 */}
      <div className="about-area bg-gradient-gray pd-top-60">
        <div className="container">
          <div className="row">
            <div
              className="col-lg-6 "
              data-aos="fade-right"
              data-aos-delay="100"
              data-aos-duration="1500"
            >
              <div className="about-thumb-inner mb-4 mb-lg-0">
                <img
                  className="main-img"
                  src={RevenueCycleManagmentOne}
                  alt="Revenue Cycle Managment Image"
                />
              </div>
            </div>
            <div
              className="col-lg-6 wow animated fadeInRight"
              data-aos="fade-left"
              data-aos-delay="100"
              data-aos-duration="1500"
            >
              <div className="section-title mb-0">
                <h2 className="title managed-it-title">
                  Maximizing Revenue through Effective
                  <span> Revenue Cycle Management </span> Services
                </h2>
                <p className="content mb-4">
                  Welcome to our page on comprehensive revenue cycle management
                  services! In today's rapidly evolving healthcare landscape,
                  healthcare providers must optimize revenue streams while
                  ensuring compliance with regulatory standards. Our customized
                  revenue cycle management services are designed to streamline
                  your organization's financial processes, allowing you to focus
                  on providing high-quality patient care.
                </p>

                <p className="content">
                  Revenue Cycle Management (RCM) involves the financial
                  processes of healthcare organizations that manage claims
                  processing, payment, and revenue generation, including patient
                  registration, insurance verification, coding, billing, and
                  collections.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Section No 02 */}
      <div className="faq-area pd-bottom-60">
        <div className="container">
          <div className="row pd-top-120">
            <div className="col-xl-7 col-lg-6 col-md-8 order-lg-last">
              <div className="about-thumb-inner video-thumb">
                <img
                  className="main-img"
                  src={RevenueCycleManagmentTwo}
                  alt="Revenue Cycle Managment Image"
                />
              </div>
            </div>
            <div className="col-xl-5 col-lg-6 align-self-center">
              <div className="section-title mb-0 mt-4 mt-lg-0">
                <h2 className="title managed-it-title">
                  Frequently Asked Questions About{" "}
                  <span> Revenue Cycle Management </span>
                  Solutions
                </h2>
                <p>
                  At Tech Matter, we understand the complexities of revenue
                  cycle management in the healthcare industry.
                </p>
              </div>
              <div
                className="accordion accordion-inner style-2 accordion-icon-left mt-3"
                id="accordionExample"
              >
                <div className="accordion-item">
                  <h2 className="accordion-header" id="headingOne">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseOne"
                      aria-expanded="false"
                      aria-controls="collapseOne"
                    >
                      Why is revenue cycle management important for healthcare
                      organizations?
                    </button>
                  </h2>
                  <div
                    id="collapseOne"
                    className="accordion-collapse collapse" //show
                    aria-labelledby="headingOne"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body">
                      Healthcare organizations can optimize revenue streams by
                      streamlining administrative processes, minimizing claim
                      denials, and accelerating reimbursement cycles with
                      effective revenue cycle management for financial
                      stability.
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header" id="headingTwo">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseTwo"
                      aria-expanded="false"
                      aria-controls="collapseTwo"
                    >
                      How can healthcare organizations get started with
                      improving their revenue cycle management processes?
                    </button>
                  </h2>
                  <div
                    id="collapseTwo"
                    className="accordion-collapse collapse"
                    aria-labelledby="headingTwo"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body">
                      Healthcare organizations should evaluate their revenue
                      cycle processes, pinpoint areas that need improvement, and
                      partner with a reputable RCM provider to optimize their
                      financial performance.
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header" id="headingThree">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseThree"
                      aria-expanded="false"
                      aria-controls="collapseThree"
                    >
                      How can I learn more about revenue cycle management
                      services offered by Tech Matter?
                    </button>
                  </h2>
                  <div
                    id="collapseThree"
                    className="accordion-collapse collapse"
                    aria-labelledby="headingThree"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body">
                      If you want to enhance your organization's financial
                      performance through revenue cycle management services,
                      contact us or visit our website. Our team provides
                      personalized consultations to comprehend your unique
                      requirements and customize our services accordingly.
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Section No 03 */}
      <div className="price-process-area pd-top-60 pd-bottom-60  mg-top-60 ">
        <div className="contact-inner-1 contact-inner-2">
          <div className="row">
            <div
              className="col-lg-6 "
              data-aos="fade-right"
              data-aos-delay="200"
              data-aos-duration="1500"
            >
              <img
                className="w-100"
                src={ManagedItServicesImageThree}
                alt="Revenue Cycle Managment Image"
              />
            </div>
            <div
              className="col-lg-4"
              data-aos="fade-left"
              data-aos-delay="100"
              data-aos-duration="1500"
            >
              <div className="section-title mb-0">
                <h2 className="title mb-4 managed-it-title">
                  Benefits of <span>Revenue Cycle Management</span>
                </h2>
                <p> Some key benefits of Revenue Cycle Management include:</p>
                <div className="media mb-3">
                  <div className="media-left me-3">
                    <img src={TickImage} alt="Tick Icon" />
                  </div>
                  <div className="media-body mg-top-20">
                    <h5> Access to expert resources and best practices.</h5>
                  </div>
                </div>
                <div className="media mb-3">
                  <div className="media-left me-3">
                    <img src={TickImage} alt="Tick Icon" />
                  </div>
                  <div className="media-body mg-top-20">
                    <h5> Cost savings through reduced overhead expenses.</h5>
                  </div>
                </div>
                <div className="media mb-3">
                  <div className="media-left me-3">
                    <img src={TickImage} alt="Tick Icon" />
                  </div>
                  <div className="media-body mg-top-20">
                    <h5>Improved focus on core clinical operations.</h5>
                  </div>
                </div>
                <div className="media mb-3">
                  <div className="media-left me-3">
                    <img src={TickImage} alt="Tick Icon" />
                  </div>
                  <div className="media-body mg-top-20">
                    <h5> Enhanced regulatory adherence.</h5>
                  </div>
                </div>
                <div className="media">
                  <div className="media-left me-3">
                    <img src={TickImage} alt="Tick Icon" />
                  </div>
                  <div className="media-body mg-top-20">
                    <h5> Flexibility to adapt to changing business needs.</h5>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* ===================== Service Details Area End ===================== */}
    </>
  );
}

export default RevenueCycleManagementDetail;
