import React from "react";
import BlogDetailsArea from "../components/BlogDetailsArea";
import Breadcrumb from "../components/Breadcrumb/Breadcrumb";
import FooterOne from "../components/Footer/FooterOne";
import NavBar from "../components/Header/NavbarTwo";
import { Helmet } from "react-helmet";

const BlogDetails = () => {
  return (
    <>
      <Helmet>
        <title>Blogs - TechMatter (Pvt) Ltd</title>
      </Helmet>
      <NavBar />
      <Breadcrumb title={"Blog Details"} />
      <BlogDetailsArea />
      <FooterOne />
    </>
  );
};

export default BlogDetails;
