import React from "react";
// import { Link } from "react-router-dom";
import bgImage from './Images/breadcrumb-banner-01.jpg'

const Breadcrumb = ({ title }) => {
  return (
    <>
      {/* ================ Breadcrumb Start ================*/}
      <div
        className='breadcrumb-area bg-cover'
        style={{ backgroundImage: `url(${bgImage})` }}
        // style={{backgroundColor: '#EDF2FA'}}
      >
        <div className='container'>
          <div className='breadcrumb-inner'>
            <div className='row justify-content-center'>
              <div className='col-lg-12'>
                <h1 className='page-title'>{title}</h1>
              </div>
              <div className='col-lg-6 text-lg-end'>
                {/* <ul className='page-list'>
                  <li>
                    <Link to='/'>Home</Link>
                  </li>
                  {"  "}/ <li>{title}</li>
                </ul> */}
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* ================ Breadcrumb End ================*/}
    </>
  );
};

export default Breadcrumb;
