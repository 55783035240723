import React from "react";
import EnterpriseImageOne from './Images/enterprise-image-one.webp'
import EnterpriseImageTwo from './Images/enterprise-image-two.webp'
import EnterpriseImageThree from './Images/enterprise-image-three.webp'
import TickImage from './Images/tick.svg'
import EnterpriseITMainBannerwep from "./Images/EnterpriseITMainBannerwep.webp";

function EnterpriseITDetail() {
  return (
    <>
      {/* ===================== Service Details Area start ===================== */}
      <div
        className="breadcrumb-area bg-cover"
        style={{ backgroundImage: `url(${EnterpriseITMainBannerwep})` }}
      >
        <div className="container">
          <div className="breadcrumb-inner">
            <div className="row justify-content-center">
              <div className="col-lg-12">
                <h1 className="page-title">Enterprise IT</h1>
              </div>
              <div className="col-lg-6 text-lg-end">
                {/* <ul className='page-list'>
                  <li>
                    <Link to='/'>Home</Link>
                  </li>
                  {"  "}/ <li>{title}</li>
                </ul> */}
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Section No 01 */}
      <div className="about-area bg-gradient-gray pd-top-60">
        <div className="container">
          <div className="row">
            <div
              className="col-lg-6 "
              data-aos="fade-right"
              data-aos-delay="100"
              data-aos-duration="1500"
            >
              <div className="about-thumb-inner mb-4 mb-lg-0">
                <img
                  className="main-img"
                  src={EnterpriseImageOne}
                  alt="EnterpriseImageOne"
                />
              </div>
            </div>
            <div
              className="col-lg-6 wow animated fadeInRight"
              data-aos="fade-left"
              data-aos-delay="100"
              data-aos-duration="1500"
            >
              <div className="section-title mb-0">
                <h2 className="title managed-it-title">
                  Our Expert <span> Enterprise IT </span> Solutions and Services
                </h2>
                <p className="content mb-4">
                  A dependable and resilient IT infrastructure is crucial to
                  success in today's fast-paced business landscape. Enterprise
                  IT Services offer all-encompassing solutions to large
                  organizations' varied requirements, ranging from network
                  management and cybersecurity to cloud computing and digital
                  transformation. This webpage delves into Enterprise IT
                  Services’ significance, advantages, and essential components.
                </p>

                <p className="content">
                  Enterprise IT Services are not just about meeting the
                  technology needs of large organizations. They are about
                  empowering businesses to leverage digital tools and systems to
                  their advantage. As the healthcare business becomes
                  increasingly demanding, the need for scalable, secure, and
                  efficient IT solutions is more pressing than ever. Our
                  Enterprise IT Services are designed to meet these complex
                  requirements and pave the way for your organization to achieve
                  its strategic objectives.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Section No 02 */}
      <div className="faq-area pd-bottom-60">
        <div className="container">
          <div className="row pd-top-120">
            <div className="col-xl-7 col-lg-6 col-md-8 order-lg-last">
              <div className="about-thumb-inner video-thumb">
                <img
                  className="main-img"
                  src={EnterpriseImageTwo}
                  alt="EnterpriseImageTwo"
                />
               
              </div>
            </div>
            <div className="col-xl-5 col-lg-6 align-self-center">
              <div className="section-title mb-0 mt-4 mt-lg-0">
                <h2 className="title managed-it-title">
                  Frequently Asked Questions About <span> Enterprise IT </span>{" "}
                  Services:
                </h2>
                <p>
                  Enterprise IT Services are designed to meet complex
                  requirements and help organizations achieve their strategic
                  goals.
                </p>
              </div>
              <div
                className="accordion accordion-inner style-2 accordion-icon-left mt-3"
                id="accordionExample"
              >
                <div className="accordion-item">
                  <h2 className="accordion-header" id="headingOne">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseOne"
                      aria-expanded="false"
                      aria-controls="collapseOne"
                    >
                      How can Enterprise IT solutions improve cybersecurity?
                    </button>
                  </h2>
                  <div
                    id="collapseOne"
                    className="accordion-collapse collapse" //show
                    aria-labelledby="headingOne"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body">
                      Enterprise IT solutions enhance cybersecurity by
                      implementing security measures such as firewalls,
                      intrusion detection systems, encryption for data
                      protection, access controls, user authentication, security
                      monitoring, and incident response.
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header" id="headingTwo">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseTwo"
                      aria-expanded="false"
                      aria-controls="collapseTwo"
                    >
                      Can enterprise IT solutions be customized to fit specific
                      business needs?
                    </button>
                  </h2>
                  <div
                    id="collapseTwo"
                    className="accordion-collapse collapse"
                    aria-labelledby="headingTwo"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body">
                      Enterprise IT solutions can be customized to meet each
                      organization's needs, objectives, and budgetary
                      constraints. IT service providers collaborate closely with
                      clients to evaluate their requirements and design bespoke
                      solutions.
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header" id="headingThree">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseThree"
                      aria-expanded="false"
                      aria-controls="collapseThree"
                    >
                      How are enterprise IT solutions managed and maintained?
                    </button>
                  </h2>
                  <div
                    id="collapseThree"
                    className="accordion-collapse collapse"
                    aria-labelledby="headingThree"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body">
                      Enterprise IT solutions are usually handled by in-house IT
                      teams or outsourced to third-party managed service
                      providers. Our professionals are responsible for essential
                      management tasks such as system monitoring,
                      troubleshooting, software updates, security patching,
                      performance tuning, and strategic planning. Our goal is to
                      ensure the system's optimal functionality and reliability.
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Section No 03 */}
      <div className="price-process-area pd-top-60 pd-bottom-60  mg-top-60 ">
        <div className="contact-inner-1 contact-inner-2">
          <div className="row">
            <div
              className="col-lg-6 "
              data-aos="fade-right"
              data-aos-delay="200"
              data-aos-duration="1500"
            >
              <img className="w-100" src={EnterpriseImageThree} alt="EnterpriseImageThree" />
            </div>
            <div
              className="col-lg-4"
              data-aos="fade-left"
              data-aos-delay="100"
              data-aos-duration="1500"
            >
              <div className="section-title mb-0">
                <h2 className="title mb-4 managed-it-title">
                  Benefits of Implementing <span>Enterprise IT</span> Solutions
                </h2>
                <p>Some key benefits of Enterprise IT include:</p>
                <div className="media mb-3">
                  <div className="media-left me-3">
                    <img src={TickImage} alt="Tick Icon" />
                  </div>
                  <div className="media-body mg-top-20">
                    <h5> Improve operational efficiency.</h5>
                  </div>
                </div>
                <div className="media mb-3">
                  <div className="media-left me-3">
                  <img src={TickImage} alt="Tick Icon" />
                  </div>
                  <div className="media-body mg-top-20">
                    <h5> Enhanced compliance and security.</h5>
                  </div>
                </div>
                <div className="media mb-3">
                  <div className="media-left me-3">
                  <img src={TickImage} alt="Tick Icon" />
                  </div>
                  <div className="media-body mg-top-20">
                    <h5> Scalability to support growth and business.</h5>
                  </div>
                </div>
                <div className="media mb-3">
                  <div className="media-left me-3">
                  <img src={TickImage} alt="Tick Icon" />
                  </div>
                  <div className="media-body mg-top-20">
                    <h5> Cost savings through task automation.</h5>
                  </div>
                </div>
                <div className="media">
                  <div className="media-left me-3">
                  <img src={TickImage} alt="Tick Icon" />
                  </div>
                  <div className="media-body mg-top-20">
                    <h5> Advanced tools for technological integration.</h5>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* ===================== Service Details Area End ===================== */}
    </>
  );
}

export default EnterpriseITDetail;
