import React from "react";
import ElectronicImageOne from "./Images/electronic-it-one.webp";
import ElectronicImageTwo from "./Images/electronic-it-two.webp";
import ElectronicImageThree from "./Images/manage-it-03.webp";
import TickImage from "./Images/tick.svg";
import ElectronicHealthRecordMainBannerwep from './Images/ElectronicHealthRecordMainBannerwep.webp'

function EhrDetail() {
  return (
    <>
      {/* ===================== Service Details Area start ===================== */}
      <div
        className="breadcrumb-area bg-cover"
        style={{ backgroundImage: `url(${ElectronicHealthRecordMainBannerwep})` }}
      >
        <div className="container">
          <div className="breadcrumb-inner">
            <div className="row justify-content-center">
              <div className="col-lg-12">
                <h1 className="page-title">Electronic Health Record</h1>
              </div>
              <div className="col-lg-6 text-lg-end">
                {/* <ul className='page-list'>
                  <li>
                    <Link to='/'>Home</Link>
                  </li>
                  {"  "}/ <li>{title}</li>
                </ul> */}
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Section No 01 */}
      <div className="about-area bg-gradient-gray pd-top-60">
        <div className="container">
          <div className="row">
            <div
              className="col-lg-6 "
              data-aos="fade-right"
              data-aos-delay="100"
              data-aos-duration="1500"
            >
              <div className="about-thumb-inner mb-4 mb-lg-0">
                <img
                  className="main-img"
                  src={ElectronicImageOne}
                  alt="ElectronicImageOne"
                />
              </div>
            </div>
            <div
              className="col-lg-6 wow animated fadeInRight"
              data-aos="fade-left"
              data-aos-delay="100"
              data-aos-duration="1500"
            >
              <div className="section-title mb-0">
                {/* <h6 className="sub-title">ABOUT US</h6> */}
                <h2 className="title managed-it-title">
                  Electronic Health Record Management System <span>(EHR)</span>
                </h2>
                <p className="content mb-4">
                  In today's digital age, Electronic Health Records (EHR)
                  Management has transformed how healthcare providers store,
                  access, and share patient information. This webpage explores
                  EHR management's importance, benefits, and key components.
                </p>

                <p className="content">
                  Electronic Health Records (EHRs) are digital versions of the
                  paper charts that doctors and hospitals used to keep. They
                  contain detailed patient history information, including
                  diagnoses, treatment plans, immunization dates, radiology
                  images, and laboratory test results. EHRs are designed to be
                  easily accessible by authorized healthcare providers from
                  different organizations, empowering them to share patient
                  information and enhancing efficiency quickly.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Section No 02 */}
      <div className="faq-area pd-bottom-60">
        <div className="container">
          <div className="row pd-top-120">
            <div className="col-xl-7 col-lg-6 col-md-8 order-lg-last">
              <div className="about-thumb-inner video-thumb">
                <img
                  className="main-img"
                  src={ElectronicImageTwo}
                  alt="ElectronicImageTwo"
                />
              </div>
            </div>
            <div className="col-xl-5 col-lg-6 align-self-center">
              <div className="section-title mb-0 mt-4 mt-lg-0">
                <h2 className="title managed-it-title">
                  Frequently Asked Questions About
                  <span> Electronic Health Records (EHR) Systems</span>
                </h2>
                <p>
                  Efficient Electronic Health Records (EHR) Management is vital
                  for delivering patient-centered care in modern healthcare
                  organizations.
                </p>
              </div>
              <div
                className="accordion accordion-inner style-2 accordion-icon-left mt-3"
                id="accordionExample"
              >
                <div className="accordion-item">
                  <h2 className="accordion-header" id="headingOne">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseOne"
                      aria-expanded="false"
                      aria-controls="collapseOne"
                    >
                      How secure is patient information in an EHR system?
                    </button>
                  </h2>
                  <div
                    id="collapseOne"
                    className="accordion-collapse collapse" //show
                    aria-labelledby="headingOne"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body">
                      Electronic Health Record (EHR) systems use effective
                      measures to protect patient health information from
                      unauthorized sources and breaches. These measures include
                      data encryption, access controls, audit trails, regular
                      security audits, and compliance with healthcare privacy
                      regulations, such as HIPAA.
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header" id="headingTwo">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseTwo"
                      aria-expanded="false"
                      aria-controls="collapseTwo"
                    >
                      How are EHR systems maintained and updated?
                    </button>
                  </h2>
                  <div
                    id="collapseTwo"
                    className="accordion-collapse collapse"
                    aria-labelledby="headingTwo"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body">
                      Regular maintenance, updates, and software patches are
                      crucial for EHR systems to maintain optimal performance,
                      security, and compliance with evolving healthcare
                      regulations. Healthcare organizations typically assign
                      dedicated IT staff or contract with EHR vendors to manage
                      these tasks, highlighting the importance of staying
                      updated and maintaining system integrity.
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header" id="headingThree">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseThree"
                      aria-expanded="false"
                      aria-controls="collapseThree"
                    >
                      How can healthcare providers get started with an EHR
                      system?
                    </button>
                  </h2>
                  <div
                    id="collapseThree"
                    className="accordion-collapse collapse"
                    aria-labelledby="headingThree"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body">
                      Suppose you are a provider who wants to implement an EHR
                      system. In that case, it is essential to research
                      different vendors, assess your specific needs and
                      requirements, and thoroughly evaluate available systems.
                      You must select an EHR system that conforms to your
                      organization's workflow, specialty, budget, and long-term
                      strategic goals. Successful EHR adoption requires careful
                      implementation planning, training, and ongoing support.
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Section No 03 */}
      <div className="price-process-area pd-top-60 pd-bottom-60  mg-top-60 ">
        <div className="contact-inner-1 contact-inner-2">
          <div className="row">
            <div
              className="col-lg-6 "
              data-aos="fade-right"
              data-aos-delay="200"
              data-aos-duration="1500"
            >
              <img
                className="w-100"
                src={ElectronicImageThree}
                alt="ElectronicImageThree"
              />
            </div>
            <div
              className="col-lg-4"
              data-aos="fade-left"
              data-aos-delay="100"
              data-aos-duration="1500"
            >
              <div className="section-title mb-0">
                <h2 className="title mb-4 managed-it-title">
                  Benefits of <span>EHR</span> Management:
                </h2>
                <p>Some key benefits of EHR Management Services include:</p>
                <div className="media mb-3">
                  <div className="media-left me-3">
                    <img src={TickImage} alt="Tick Icon" />
                  </div>
                  <div className="media-body mg-top-20">
                    <h5>Improved patient outcomes.</h5>
                  </div>
                </div>
                <div className="media mb-3">
                  <div className="media-left me-3">
                    <img src={TickImage} alt="Tick Icon" />
                  </div>
                  <div className="media-body mg-top-20">
                    <h5> Cost savings for healthcare organizations.</h5>
                  </div>
                </div>
                <div className="media mb-3">
                  <div className="media-left me-3">
                    <img src={TickImage} alt="Tick Icon" />
                  </div>
                  <div className="media-body mg-top-20">
                    <h5> EHRs streamline administrative processes.</h5>
                  </div>
                </div>
                <div className="media mb-3">
                  <div className="media-left me-3">
                    <img src={TickImage} alt="Tick Icon" />
                  </div>
                  <div className="media-body mg-top-20">
                    <h5> Facilitation of care coordination.</h5>
                  </div>
                </div>
                <div className="media">
                  <div className="media-left me-3">
                    <img src={TickImage} alt="Tick Icon" />
                  </div>
                  <div className="media-body mg-top-20">
                    <h5> Interoperability between other departments.</h5>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* ===================== Service Details Area End ===================== */}
    </>
  );
}

export default EhrDetail;
