import React from "react";
import NavBar from "../components/Header/NavbarTwo";
import FooterOne from "../components/Footer/FooterTwo";
import { Helmet } from "react-helmet";
import PageNotFoundDetails from "../components/PageNotFound/PageNotFoundDetails";

function PageNotFound() {
  return (
    <>
      <Helmet>
        <title> 404 - TechMatter (Pvt) Ltd </title>
      </Helmet>
      <NavBar />
      <PageNotFoundDetails />
      <FooterOne />
    </>
  );
}

export default PageNotFound;
