import React from "react";
import { Link } from "react-router-dom";

const WorkProcessOne = () => {
  return (
    <>
      {/*==================== Work Process One start ====================*/}
   
      <div className='work-process-area pd-top-40 pd-bottom-40 work-process-bg'>
        <div className='container'>
          {/* <div className='section-title text-center'>
            <h6 className='sub-title'>OUR SIMPLE PROCESS</h6>
            <h2 className='title'>
              World <span>Best Step</span> Our It Process
            </h2>
          </div> */}
          <div className='row'>
            <div className='col-xl-3 col-md-6'>
              <div className='single-work-process-inner single-work-process-inner-01'>
                <div className='thumb mb-3'>
                  <img src='assets/img/icon/customer-satisfaction.png' alt='Customer Satisfaction' />
                </div>
                <div className='details'>
                  <p className='process-count'>Customer Satisfaction</p>
                  {/* <h5 className='mb-3'>Software Research</h5>
                  <p className='content'>
                    Research ipsum dolor sit consec tetur sed diam in the
                    aliquam tempor
                  </p> */}
                </div>
              </div>
            </div>
            <div className='col-xl-3 col-md-6'>
              <div className='single-work-process-inner single-work-process-inner-01'>
                <div className='thumb mb-3'>
                  <img src='assets/img/icon/innovation-creativity.png' alt='Innovation & Creativity' />
                </div>
                <div className='details'>
                  <p className='process-count'>Innovation and Creativity</p>
                  {/* <h5 className='mb-3'>Create Programs</h5>
                  <p className='content'>
                    Create ipsum dolor sit consec tetur sed diam in the aliquam
                    tempor
                  </p> */}
                </div>
              </div>
            </div>
            <div className='col-xl-3 col-md-6'>
              <div className='single-work-process-inner single-work-process-inner-01'>
                <div className='thumb mb-3'>
                  <img src='assets/img/icon/our-personnel.png' alt='Our Personnel' />
                </div>
                <div className='details'>
                  <p className='process-count'>RCM Experts</p>
                  {/* <h5 className='mb-3'>Develope Software</h5>
                  <p className='content'>
                    Develope ipsum dolor sit consec tetur sed diam in the
                    aliquam tempor
                  </p> */}
                </div>
              </div>
            </div>
            <div className='col-xl-3 col-md-6'>
              <div className='single-work-process-inner single-work-process-inner-01'>
                <div className='thumb mb-3'>
                  <img src='assets/img/icon/proactive-support.png' alt='24/7 Proactive Support' />
                </div>
                <div className='details'>
                  <p className='process-count'>24/7 Proactive Support</p>
                  {/* <h5 className='mb-3'>Shop software</h5>
                  <p className='content'>
                    Shop ipsum dolor sit consec tetur Malesuada sed diam in the
                    aliquam tempor
                  </p> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* ==================== Work Process One end ====================*/}
    </>
  );
};

export default WorkProcessOne;
