import React, { useRef, useState } from "react";
import emailjs from "@emailjs/browser";
import { toast, Toaster } from "react-hot-toast";
import TrackVisibility from "react-on-screen";
import CountUp from "react-countup";
import firstImage from "../About/Images/1.png";
import fiveSvg from "../About/Images/5.svg";
import TwoImage from "../About/Images/2.png";
import ThreeImage from "../About/Images/3.png";
import IconTwo from "../About/Images/2-after-paragraph.png";
import IconThree from "../About/Images/3-after-paragraph.png";
import FaqTwo from "../About/Images/faq-two.png";
import FaqThree from "../About/Images/faq-three.png";
import CounterIconOne from "../About/Images/counter-icon-one.svg";
import CounterIconTwo from "../About/Images/counter-icon-two.svg";
import CounterIconThree from "../About/Images/counter-icon-three.svg";
import CounterIconFour from "../About/Images/counter-icon-four.svg";
import ContactAboutBanner from "../About/Images/contact-about-banner.jpg";
import LeftBanner from "../About/Images/contact-about-left-banner-image.png";
import RightBanner from "../About/Images/contact-about-right-banner-image.png";
import AboutBanner from "../About/Images/about-banner.webp";
import ReCAPTCHA from "react-google-recaptcha";
import { Link } from "react-router-dom";
import ModalVideo from "react-modal-video";
import './aboutstyle.css'

const AboutAreaOne = () => {
  const [isOpen, setOpen] = useState(false);

  const form = useRef();
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    subject: "",
    message: "",
  });
  const [recaptchaToken, setRecaptchaToken] = useState("");

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleRecaptchaChange = (token) => {
    setRecaptchaToken(token);
  };

  const sendEmail = (e) => {
    e.preventDefault();
    if (!recaptchaToken) {
      toast.error("Please complete the reCAPTCHA!");
      return;
    }
    emailjs
      .sendForm(
        "service_7uzhm0r", // YOUR_SERVICE_ID
        "template_x2g6v7a", // YOUR_TEMPLATE_ID
        form.current,
        "y_8tLXr9YjarjhSHW" // YOUR_USER_ID
      )
      .then(
        (result) => {
          if (result.status === 200) {
            toast.success("Message Sent Successfully!");
            form.current.reset(); // Reset the form fields
            setFormData({
              name: "",
              email: "",
              subject: "",
              message: "",
            });
          } else {
            toast.error("Failed to send message!");
          }
        },
        (error) => {
          toast.error("Failed to send message!");
          console.error("EmailJS error:", error);
        }
      );
  };

  return (
    <>
      {/* ================== AboutAreaOne start  ==================*/}
      <div
        className="aboutCoverImage"
        style={{ backgroundImage: `url(${AboutBanner})` }}
      >
        <div className="overlayAbout"></div>
        <Link
          to="#"
          onClick={() => setOpen(true)}
          className="video-play-btn"
        >
          <img src="assets/img/video.svg" alt="Play Video" />
        </Link>
      </div>
      <div className="about-area pd-top-60">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <div
                className="about-thumb-inner pe-xl-5 me-xl-5 "
                data-aos="fade-right"
                data-aos-delay="100"
                data-aos-duration="1500"
              >
                <img
                  className="animate-img-1 top_image_bounce"
                  src={TwoImage}
                  alt="About Second Image"
                />
                <img
                  className="animate-img-2 left_image_bounce"
                  src={ThreeImage}
                  alt="About Three Image"
                />
                <img
                  className="animate-img-3 top_image_bounce"
                  src={fiveSvg}
                  alt="About Five Svg"
                />
                <img
                  className="animate-img-3 top_image_bounce"
                  src={fiveSvg}
                  alt="About Five Svg"
                />
                <img className="main-img" src={firstImage} alt="About First Image" />
              </div>
            </div>
            <div
              className="col-lg-6 "
              data-aos="fade-left"
              data-aos-delay="100"
              data-aos-duration="1500"
            >
              <div className="section-title mt-5 mt-lg-0">
                <h1 className="title titleFontAbout">
                  Grow Your Business with <span> Strategic </span> IT Solutions
                </h1>
                <p className="content mb-4 mb-xl-5">
                  TechMatter is a team of tech enthusiasts, marketing experts,
                  and creative professionals. We're dedicated to helping
                  businesses succeed with our experience, expertise, and
                  integrity. We aim to provide outstanding services to help our
                  clients meet their objectives.
                </p>

                <div className="row">
                  <div className="col-md-6">
                    <div className="single-about-inner">
                      <div className="thumb mb-3">
                        <img src={IconTwo} alt="About Icon Two" />
                      </div>
                      <div className="details">
                        <h5>Proactive IT Support</h5>
                        <p>
                          Our managed IT services aim to ensure your systems
                          remain optimized for peak performance by preventing
                          issues before they arise.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="single-about-inner">
                      <div className="thumb mb-3">
                        <img src={IconThree} alt="About Icon Three" />
                      </div>
                      <div className="details">
                        <h5>Integrated Marketing Approach</h5>
                        <p>
                          We create holistic strategies that deliver maximum
                          impact by integrating IT solutions with strategic
                          marketing campaigns.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className="faq-area faq-area-margin-top bg-cover pd-top-90 pd-bottom-110"
      //  style={{ backgroundImage: 'url("./assets/img/bg/3.png")' }}
      >
        <div className="container">
          <div className="row pd-top-120">
            <div
              className="col-xl-5 col-lg-6 col-md-8 order-lg-last"
              data-aos="fade-left"
              data-aos-delay="100"
              data-aos-duration="1500"
            >
              <div className="about-thumb-inner pt-lg-3">
                <img className="main-img" src={FaqTwo} alt="About Faq Icon Two" />
                <img
                  className="animate-img-bottom-right top_image_bounce"
                  src={FaqThree}
                  alt="About Faq Icon Three"
                />
              </div>
            </div>

            <div
              className="col-xl-7 col-lg-6"
              data-aos="fade-right"
              data-aos-delay="100"
              data-aos-duration="1500"
            >
              <div className="section-title mb-0 mt-4 mt-lg-0">
                {/* <h6 className="sub-title">SOME FAQ'S</h6> */}
                <h2 className="title">
                  <span> Any Queries? </span> We are here to assist:
                </h2>
                <p className="content">
                  Contact Tech Matter to schedule an IT consultation. We'll
                  evaluate your infrastructure, discuss your needs, and suggest
                  personalized solutions.
                </p>
              </div>
              <div
                className="accordion accordion-inner style-2 accordion-icon-left mt-3"
                id="accordionExample"
              >
                <div className="accordion-item">
                  <h2 className="accordion-header" id="headingOne">
                    <button
                      className="accordion-button"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseOne"
                      aria-expanded="true"
                      aria-controls="collapseOne"
                    >
                      What services does Tech Matter offer?
                    </button>
                  </h2>
                  <div
                    id="collapseOne"
                    className="accordion-collapse collapse show"
                    aria-labelledby="headingOne"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body">
                      Tech Matter specializes in managed IT services and
                      Software Development. Other services include Revenue Cycle
                      Management, Healthcare Staff Augmentation, EHR, and
                      Enterprise IT Solutions.
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header" id="headingTwo">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseTwo"
                      aria-expanded="false"
                      aria-controls="collapseTwo"
                    >
                      How can Tech Matter help my business grow?
                    </button>
                  </h2>
                  <div
                    id="collapseTwo"
                    className="accordion-collapse collapse"
                    aria-labelledby="headingTwo"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body">
                      Tech Matter enhances your online presence, streamlines IT
                      operations, and optimizes digital marketing. Get a new
                      website, custom software, or marketing strategy guidance.
                      Thrive in the digital landscape with Tech Matter.
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header" id="headingThree">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseThree"
                      aria-expanded="false"
                      aria-controls="collapseThree"
                    >
                      What sets Tech Matter apart from other IT service
                      providers?
                    </button>
                  </h2>
                  <div
                    id="collapseThree"
                    className="accordion-collapse collapse"
                    aria-labelledby="headingThree"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body">
                      At Tech Matter, we are committed to innovation, quality,
                      and customer satisfaction. Our skilled professionals
                      leverage technology to drive business success, exceeding
                      client expectations with effective and reliable solutions.
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className="counter-area bg-relative bg-cover pd-top-110 pd-bottom-100"
        style={{ backgroundImage: 'linear-gradient(180deg, #D4E5F5 0%, #FFFFFF 100%)' }}

      >
        <div className="container pd-bottom-90">
          <div className="row">
            <div
              className="col-lg-3 col-md-6 "
              data-aos="fade-up"
              data-aos-delay="100"
              data-aos-duration="1500"
            >
              <div className="single-counter-inner media">
                <div className="thumb media-left pe-4">
                  <img src={CounterIconOne} alt="Customer Satisfaction" />
                </div>
                <div className="media-body">
                  <h4 className="mb-1">
                    <TrackVisibility once>
                      {({ isVisible }) =>
                        isVisible && (
                          <span className="counter">
                            <CountUp delay={0} start={0} end={98} /> %
                          </span>
                        )
                      }
                    </TrackVisibility>
                  </h4>
                  <p className="mb-0">Customer Satisfaction</p>
                </div>
              </div>
            </div>
            <div
              className="col-lg-3 col-md-6 "
              data-aos="fade-up"
              data-aos-delay="150"
              data-aos-duration="1500"
            >
              <div className="single-counter-inner media">
                <div className="thumb media-left pe-4">
                  <img src={CounterIconTwo} alt="Rise in Productivity" />
                </div>
                <div className="media-body">
                  <h4 className="mb-1">
                    <TrackVisibility once>
                      {({ isVisible }) =>
                        isVisible && (
                          <span className="counter">
                            <CountUp delay={0} start={0} end={60} /> %
                          </span>
                        )
                      }
                    </TrackVisibility>
                  </h4>
                  <p className="mb-0">Rise in Productivity</p>
                </div>
              </div>
            </div>
            <div
              className="col-lg-3 col-md-6 "
              data-aos="fade-up"
              data-aos-delay="200"
              data-aos-duration="1500"
            >
              <div className="single-counter-inner media">
                <div className="thumb media-left pe-4">
                  <img src={CounterIconThree} alt="User Experiences" />
                </div>
                <div className="media-body">
                  <h4 className="mb-1">
                    <TrackVisibility once>
                      {({ isVisible }) =>
                        isVisible && (
                          <span className="counter">
                            <CountUp delay={0} start={0} end={850} /> +
                          </span>
                        )
                      }
                    </TrackVisibility>
                  </h4>
                  <p className="mb-0">User Experiences</p>
                </div>
              </div>
            </div>
            <div
              className="col-lg-3 col-md-6 "
              data-aos="fade-up"
              data-aos-delay="250"
              data-aos-duration="1500"
            >
              <div className="single-counter-inner media">
                <div className="thumb media-left pe-4">
                  <img src={CounterIconFour} alt="IT Professionals" />
                </div>
                <div className="media-body">
                  <h4 className="mb-1">
                    <TrackVisibility once>
                      {({ isVisible }) =>
                        isVisible && (
                          <span className="counter">
                            <CountUp delay={0} start={0} end={500} /> +
                          </span>
                        )
                      }
                    </TrackVisibility>
                  </h4>
                  <p className="mb-0">IT Professionals</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Toaster position="bottom-center" reverseOrder={false} />

      <div className="contact-area pd-bottom-110">
        <div className="container">
          <div className="contact-inner-1">
            <img
              className="top_image_bounce animate-img-1"
              src={LeftBanner}
              alt="Contact Section Left Banner"
            />
            <img
              className="top_image_bounce animate-img-2"
              src={RightBanner}
              alt="Contact Section Right Banner"
            />
            <div className="row">
              <div
                className="col-lg-8"
                data-aos="fade-right"
                data-aos-delay="200"
                data-aos-duration="1500"
              >
                <img className="w-100" src={ContactAboutBanner} alt="Contact About Banner" />
              </div>
              <div
                className="col-lg-4 wow animated fadeInRight"
                data-aos="fade-left"
                data-aos-delay="200"
                data-aos-duration="1500"
              >
                <div className="section-title mb-0">
                  {/* <h6 className='sub-title'>GET IN TOUCH</h6> */}
                  <h2 className="title">
                    Schedule a <span> Free </span> Consultation
                  </h2>
                  <p className="content">
                    We’re happy to answer any questions you may have and help
                    you determine which of our services best fit your needs.
                  </p>
                  <form className="mt-4" ref={form} onSubmit={sendEmail}>
                    <div className="row">
                      <div className="col-lg-12">
                        <div className="single-input-inner style-border">
                          <input
                            type="text"
                            placeholder="Name"
                            name="name"
                            id="name"
                            value={formData.name}
                            onChange={handleChange}
                            required
                          />
                        </div>
                      </div>
                      <div className="col-lg-12">
                        <div className="single-input-inner style-border">
                          <input
                            type="text"
                            placeholder="Email"
                            name="email"
                            id="email"
                            value={formData.email}
                            onChange={handleChange}
                            required
                          />
                        </div>
                      </div>

                      <div className="col-lg-12">
                        <div className="single-input-inner style-border">
                          <input
                            type="text"
                            placeholder="Subject"
                            name="subject"
                            id="subject"
                            value={formData.subject}
                            onChange={handleChange}
                            required
                          />
                        </div>
                      </div>
                      <div className="col-lg-12">
                        <div className="single-input-inner style-border">
                          <textarea
                            defaultValue={""}
                            placeholder="Message"
                            name="message"
                            id="message"
                            value={formData.message}
                            onChange={handleChange}
                            required
                          />
                        </div>
                      </div>
                      {/* reCAPTCHA */}
                      <div className="col-12">
                        <ReCAPTCHA
                          sitekey="6LfHHcwpAAAAADQ04Kjgsvyx0hEZ1AkgfXPXQyzi"
                          onChange={handleRecaptchaChange}
                        />
                      </div>
                      <div className="col-12">
                        <button
                          className="btn btn-black mt-0 w-100 border-radius-5"
                          type="submit"
                        >
                          Submit
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* ================== AboutAreaOne End  ==================*/}
      <ModalVideo
        channel="youtube"
        autoplay
        isOpen={isOpen}
        videoId="3aWZAigOI0g"
        onClose={() => setOpen(false)}
      />
    </>
  );
};

export default AboutAreaOne;
