import React from "react";
import { FaCheckCircle, FaPlus } from "react-icons/fa";
import { Link } from "react-router-dom";
import HomeAboutImage from './Images/10.webp'

const scrollToTop = () => {
  window.scrollTo(0, 0);
};

const AboutAreaTwo = () => {
  return (
    <>
      {/* =============== About Area Two End ===============*/}
      {/* <div className="about-area pd-top-120 pd-bottom-120"> */}
      <div className="about-area pd-top-30 pd-bottom-60">
        <div className="container">
          <div className="row">
            <div
              className="col-lg-6 mb-4 mb-lg-0 "
              data-aos="fade-right"
              data-aos-delay="100"
              data-aos-duration="1500"
            >
              <div className="about-thumb-inner">
                <img
                  className="main-img"
                  src={HomeAboutImage}
                  alt="Home About Image"
                />
              </div>
            </div>
            <div
              className="col-lg-6 "
              data-aos="fade-left"
              data-aos-delay="100"
              data-aos-duration="1500"
            >
              <div className="section-title mb-0">
                {/* <h6 className="sub-title">ABOUT US</h6> */}
                <h2 className="title">Your Partner in <span> Managed IT </span>Services</h2>
                <p className="content mb-4">
                  Tech Matter collaborates with organizations to offer
                  customized, top-quality, fully managed cloud and IT
                  infrastructure solutions that are dependable and
                  high-performance. Our managed IT services allow businesses in
                  the IT sector to concentrate on accomplishing their objectives
                  rather than being preoccupied with data backup and management
                  tasks.
                </p>
                <div className="row">
                  <div className="col-md-6">
                    <ul className="single-list-inner style-check style-heading style-check mb-3">
                      <li>
                        <FaCheckCircle /> Expert IT Professionals.
                      </li>
                      <li>
                        <FaCheckCircle />
                        Free Consultation.
                      </li>
                    </ul>
                  </div>
                  <div className="col-md-6">
                    <ul className="single-list-inner style-check style-heading style-check mb-3">
                      <li>
                        <FaCheckCircle />
                        Reliability and Cybersecurity.
                      </li>
                      <li>
                        <FaCheckCircle /> IT Management Solutions.
                      </li>
                    </ul>
                  </div>
                </div>
                {/* <p className="content">
                  SaaS providers typically invest heavily in security measures
                  to protect customer data, such as encryption and multi-factor
                  authentication. However, it is important to research and
                  choose a reputable provider and also to ensure that
                </p> */}
                <Link className="btn btn-border-base" to="/about" onClick={scrollToTop}>
                  About <FaPlus />
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* =============== About Area Two End ===============*/}
    </>
  );
};

export default AboutAreaTwo;
