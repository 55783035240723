import React from "react";
import TrackVisibility from "react-on-screen";
import CountUp from "react-countup";
import CustomerSatisfaction from '../Counter/Images/customer-satisfaction.svg'
import RiseInProductivity from '../Counter/Images/rise-in-productivity.svg'
import UserExperiences from '../Counter/Images/user-experiences.svg'
import ITProfessionals from '../Counter/Images/it-professional.svg'


const CounterAreaThree = () => {
  return (
    <>
      {/*================= counter area start {/*=================*/}
      <div className="counter-area bg-base pd-top-30 pd-bottom-30">
        <div className="container">
          <div className="row">
            <div className="col-lg-3 col-md-6">
              <div className="single-counter-inner style-2 text-center">
                <div className="thumb">
                  <img src={CustomerSatisfaction} alt="CustomerSatisfaction" />
                </div>
                <h2 className="text-white mt-3 mb-2">
                  <TrackVisibility once>
                    {({ isVisible }) =>
                      isVisible && (
                        <span className="counter">
                          <CountUp delay={0} start={0} end={98} /> %
                        </span>
                      )
                    }
                  </TrackVisibility>
                </h2>
                <p className="text-white">Customer Satisfaction</p>
              </div>
            </div>
            <div className="col-lg-3 col-md-6">
              <div className="single-counter-inner style-2 text-center">
                <div className="thumb">
                  <img src={RiseInProductivity} alt="RiseInProductivity" />
                </div>
                <h2 className="text-white mt-3 mb-2">
                  <TrackVisibility once>
                    {({ isVisible }) =>
                      isVisible && (
                        <span className="counter">
                          <CountUp delay={0} start={0} end={60} /> %
                        </span>
                      )
                    }
                  </TrackVisibility>
                </h2>
                <p className="text-white">Rise in Productivity</p>
              </div>
            </div>
            <div className="col-lg-3 col-md-6">
              <div className="single-counter-inner style-2 text-center">
                <div className="thumb">
                  <img src={UserExperiences} alt="UserExperiences" />
                </div>
                <h2 className="text-white mt-3 mb-2">
                  <TrackVisibility once>
                    {({ isVisible }) =>
                      isVisible && (
                        <span className="counter">
                          <CountUp delay={0} start={0} end={850} /> +
                        </span>
                      )
                    }
                  </TrackVisibility>
                </h2>
                <p className="text-white">User Experiences</p>
              </div>
            </div>
            <div className="col-lg-3 col-md-6">
              <div className="single-counter-inner style-2 text-center">
                <div className="thumb">
                  <img src={ITProfessionals} alt="ITProfessionals" />
                </div>
                <h2 className="text-white mt-3 mb-2">
                  <TrackVisibility once>
                    {({ isVisible }) =>
                      isVisible && (
                        <span className="counter">
                          <CountUp delay={0} start={0} end={500} /> +
                        </span>
                      )
                    }
                  </TrackVisibility>
                </h2>
                <p className="text-white">IT Professionals</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/*{/*================= counter area end {/*=================*/}
    </>
  );
};

export default CounterAreaThree;
