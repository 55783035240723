import React, { useState } from "react";
import { FaPlus, FaSearch } from "react-icons/fa";
import { Link } from "react-router-dom";
import ArrowDown from "../Header/Images/arrow-down.png";
import TechmatterLogo from "../Header/Images/techmatter-logo.webp";

const NavbarTwo = () => {
  const [active, setActive] = useState(false);
  const [searchShow, setSearchShow] = useState(false);
  const menuActive = () => {
    setActive(!active);
  };
  const searchActive = () => {
    setSearchShow(!searchShow);
    console.log("hell");
  };

  let items = document.querySelectorAll(".menu-item-has-children > a");
  for (let i in items) {
    if (items.hasOwnProperty(i)) {
      items[i].onclick = function () {
        this.parentElement
          .querySelector(".sub-menu")
          .classList.toggle("active");
        this.classList.toggle("open");
      };
    }
  }
  return (
    <>
      {/* ==================== Navbar Two Start ====================*/}
      <nav className="navbar navbar-area navbar-area-2 navbar-expand-lg">
        <div className="container nav-container">
          <div className="responsive-mobile-menu">
            <button
              onClick={menuActive}
              className={
                active
                  ? "menu toggle-btn d-block d-lg-none open"
                  : "menu toggle-btn d-block d-lg-none"
              }
              data-target="#itech_main_menu"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="icon-left" />
              <span className="icon-right" />
            </button>
          </div>
          <div className="logo">
            <Link to="/">
              <img src={TechmatterLogo} alt="Techmatter Logo" />
            </Link>
          </div>

          <div
            className={
              active
                ? "collapse navbar-collapse sopen"
                : "collapse navbar-collapse"
            }
            id="itech_main_menu"
          >
            <ul className="navbar-nav menu-open text-lg-end">
              <li>
                {" "}
                <Link to="/">Home</Link>
              </li>
              <li className="menu-item-has-children service-menu">
                <Link to="/service">Service </Link>
                <img
                  src={ArrowDown}
                  width={12}
                  height={12}
                  className="arrow-image"
                />
                <ul className="sub-menu">
                  <li>
                    <Link to="/managed-it-services">Managed IT services</Link>
                  </li>
                  <li>
                    <Link to="/revenue-cycle-management">
                      Revenue Cycle Management
                    </Link>
                  </li>
                  <li>
                    <Link to="/enterprise-it">Enterprise IT </Link>
                  </li>
                  <li>
                    <Link to="/electronic-health-record">
                      Electronic Health Record{" "}
                    </Link>
                  </li>
                  <li>
                    <Link to="/healthcare-staff-augmentation">
                      Healthcare Staff Augmentation
                    </Link>
                  </li>
                  <li>
                    <Link to="/marketing">Marketing</Link>
                  </li>
                </ul>
              </li>
              <li>
                <Link to="/about">About</Link>
              </li>
              <li>
                <Link to="/blogs">Blogs</Link>
              </li>
              <li>
                <Link to="/contact">Contact</Link>
              </li>
            </ul>
          </div>
          <div className="nav-right-part nav-right-part-desktop align-self-center">
            <Link className="btn btn-border-base" to="/contact">
              Get Started <FaPlus />
            </Link>
          </div>
        </div>
      </nav>
    </>
  );
};

export default NavbarTwo;
