import React from "react";
import { FaCalendarAlt, FaRegComments, FaRegUser } from "react-icons/fa";
import { Link } from "react-router-dom";
import HomeBlogSection from "./blogs/HomeBlogSection";

const scrollToTop = () => {
  window.scrollTo(0, 0);
};

const BlogAreaOne = () => {
  return (
    <>
      {/*===================== Blog Area One start =====================*/}
      <div className="blog-area pd-top-120 pd-bottom-30">
        <div className="container">
          <div className="section-title text-center">
            <h2 className="title">
              Latest <span> News </span> & <span> Articles </span>
            </h2>
          </div>
          <div>
            <HomeBlogSection />
          </div>
          {/* <div className="row">
            <div className="col-lg-4 col-md-6">
              <div className="single-blog-list">
                <div className="thumb">
                  <img src="assets/img/blog/home-first-blog.png" alt="img" />
                </div>
                <div className="details">
                  <h5>
                    <Link
                      to="/what-is-a-network-prefix-length"
                      onClick={scrollToTop}
                    >
                      What is a network prefix length?{" "}
                    </Link>
                  </h5>
                  <p className="mt-2">
                    The prefix length in CIDR notation reflects the number of
                    network bits in the subnet mask...
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="single-blog-list">
                <div className="thumb">
                  <img src="assets/img/blog/home-second-blog.png" alt="img" />
                </div>
                <div className="details">
                  <h5>
                    <Link
                      to="/what-is-network-stack-in-bios"
                      onClick={scrollToTop}
                    >
                      What is network stack in bios?
                    </Link>
                  </h5>
                  <p>
                    Looking for complete information regarding the network stack
                    or network stack in BIOS (Basic Input/Output System)?...
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="single-blog-list">
                <div className="thumb">
                  <img src="assets/img/blog/home-third-blog.png" alt="img" />
                </div>
                <div className="details">
                  <h5>
                    <Link
                      to="/what-is-difference-between-Oracle-12c-and-19c
"
                      onClick={scrollToTop}
                    >
                      What is difference between Oracle 12c and 19c?
                    </Link>
                  </h5>
                  <p>
                    Greetings from the Oracle realm, my fellow explorers!...
                  </p>
                </div>
              </div>
            </div>
          </div> */}
        </div>
      </div>
      {/* ===================== BlogAreaOne End =====================*/}
    </>
  );
};

export default BlogAreaOne;
