import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { ApolloClient, InMemoryCache, gql } from "@apollo/client";
import NavbarTwo from "../Header/NavbarTwo";
import FooterOne from "../Footer/FooterTwo";
import blogBanner from './Images/blog-wp.webp'

const client = new ApolloClient({
  uri: "https://techmatterglobal.com/blog/graphql",
  cache: new InMemoryCache(),
});

const scrollToTop = () => {
  window.scrollTo(0, 0);
};

function decodeHTMLEntities(text) {
  return text.replace(/&#(\d+);/g, function (match, dec) {
    return String.fromCharCode(dec);
  });
}
const BlogDetail = () => {
  const [posts, setPosts] = useState([]);
  const [filteredPosts, setFilteredPosts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [page, setPage] = useState(1);

  useEffect(() => {
    setLoading(true);
    client
      .query({
        query: gql`
          query GetPosts($first: Int!, $searchTerm: String) {
            posts(first: $first, where: {search: $searchTerm}) {
              nodes {
                id
                title
                slug
                content
                date
                featuredImage {
                  node {
                    sourceUrl
                  }
                }
              }
            }
          }
        `,
        variables: { first: page * 6, searchTerm },
      })
      .then((result) => {
        const formattedPosts = result.data.posts.nodes.map((post) => ({
          ...post,
          date: formatDate(post.date),
        }));
        if (page === 1) {
          setPosts(formattedPosts);
          setFilteredPosts(formattedPosts);
        } else {
          // Filter out duplicate posts before updating the state
          const newPosts = formattedPosts.filter((newPost) => {
            return !posts.some((oldPost) => oldPost.id === newPost.id);
          });
          setPosts([...posts, ...newPosts]);
          setFilteredPosts([...filteredPosts, ...newPosts]);
        }
        setLoading(false);
      })
      .catch((error) => {
        console.error(error);
        setLoading(false);
      });
  }, [searchTerm, page]);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (searchTerm.trim() !== "") {
        setSearchTerm(searchTerm.trim());
      }
    }, 500);

    return () => clearTimeout(timer);
  }, [searchTerm]);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString("en-US", {
      year: "numeric",
      month: "long",
      day: "numeric",
    });
  };

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };

  const loadMore = () => {
    setPage(page + 1);
  };

  return (
    <>
      <NavbarTwo />
      <div
        className="breadcrumb-area bg-cover"
        style={{ backgroundImage: `url(${blogBanner})` }}
      >
        <div className="container">
          <div className="breadcrumb-inner">
            <div className="row justify-content-center">
              <div className="col-lg-12">
                <h1 className="page-title">Our Blogs</h1>
              </div>
              <div className="col-lg-6 text-lg-end">
                {/* <ul className='page-list'>
                  <li>
                    <Link to='/'>Home</Link>
                  </li>
                  {"  "}/ <li>{title}</li>
                </ul> */}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container pd-top-90 pd-bottom-90">
        <input
          type="text"
          placeholder="Search blogs..."
          value={searchTerm}
          onChange={handleSearch}
          className="form-control mr-sm-2 inputSearchField"
        />
        <div className="row">
          {filteredPosts.map((post) => (
            <div className="col-md-4 mb-4" key={post.id}>
              <div className="card blogcard">
                {post.featuredImage && post.featuredImage.node && (
                  <img
                    src={post.featuredImage.node.sourceUrl}
                    className="card-img-top"
                    alt="Blog Featured Image"
                  />
                )}
                <div className="card-body">
                  <h5 className="card-title">
                    <Link to={`/blogs/${post.slug}`} onClick={scrollToTop}>{post.title}</Link>
                  </h5>
                  <p
                    className="card-text"
                    style={{
                      WebkitLineClamp: 3,
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      display: "-webkit-box",
                      WebkitBoxOrient: "vertical",
                    }}
                  >
                    {/* {post.content.replace(/<[^>]+>/g, "")} */}
                    {/* {post.content.replace(/&#8217;/g, "'").replace(/<[^>]+>/g, "")} */}
                    {decodeHTMLEntities(post.content.replace(/<[^>]+>/g, ""))}
                  </p>
                  <p className="card-text">
                    <small className="text-muted">{post.date}</small>
                  </p>
                </div>
              </div>
            </div>
          ))}
        </div>
        {loading && <p>Loading...</p>}
        {!loading && (
          <button
            onClick={loadMore}
            disabled={loading}
            className="btn btn-primary blogbutton"
            style={{ display: "block", margin: "auto" }}
          >
            Load More
          </button>
        )}
      </div>
      <FooterOne />
    </>
  );
};

export default BlogDetail;
