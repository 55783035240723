import React from "react";
import FooterOne from "../components/Footer/FooterTwo";
import NavBar from "../components/Header/NavbarTwo";
import EhrDetail from "../components/Service/ServiceList/EhrDetail";
import CounterAreaThree from "../components/Counter/CounterAreaThree";
import { Helmet } from "react-helmet";

function Ehr() {
  const schemaMarkup = `
  {
    "@context": "http://schema.org",
    "@type": "service",
    "name": "Electronic Health Record Services",
    "description": "Streamline your medical practice with comprehensive EHR services tailored to your needs. From implementation to ongoing management, trust our expertise for seamless EHR integration and optimization.",
    "url": "https://techmatterglobal.com/electronic-health-record/",
    "provider": {
      "@type": "Organization",
      "name": "TechMatter (Pvt) Ltd",
      "url": "https://techmatterglobal.com/",
      "logo": "https://techmatterglobal.com/static/media/techmatter-logo.18ba9962b358c5d14134.webp",
      "description": "TechMatter (Pvt) Ltd is a leading Top-tier IT managed services provider. Specializing in security, helpdesk, and comprehensive IT support solutions.",
      "telephone": "(021) 37447307",
      "email": "info@techmatterglobal.com",
      "sameAs": [
        "https://www.linkedin.com/company/techmatter-pvt-ltd/",
        "https://twitter.com/techmatterpvt",
        "https://www.facebook.com/techmatterglobal/",
        "https://www.instagram.com/techmatterglobal/"
      ],
      "address": {
        "@type": "PostalAddress",
        "streetAddress": "194-N, Block 2 PECHS",
        "addressLocality": "Karachi",
        "addressRegion": "Sindh",
        "addressCountry": "Pakistan"
      }
    }
  }
  
  `;
  return (
    <>
      <Helmet>
        <meta
          name="title"
          content="Electronic Health Record Management System (EHR) - TechMatter (PVT) Ltd "
        />

        <meta
          name="description"
          content="Streamline your medical practice with EHR services tailored to your needs. From implementation to ongoing management, trust our expertise for seamless EHR optimization. "
        />

        <meta
          name="keywords"
          content="EHR Services for Medical Practices, EHR Management Services "
        />

        <link
          rel="canonical"
          href="https://techmatterglobal.com/electronic-health-record"
        />
        <title>Electronic Health Record Management System (EHR) - TechMatter (PVT) Ltd </title>

        <script type="application/ld+json">{schemaMarkup}</script>
      </Helmet>
      <NavBar />
      <EhrDetail />
      <CounterAreaThree />
      <FooterOne />
    </>
  );
}

export default Ehr;
