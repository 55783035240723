// import React from "react";
// import BlogGroup from "../components/BlogGroup";
// import Breadcrumb from "../components/Breadcrumb/Breadcrumb";
// import FooterOne from "../components/Footer/FooterTwo";
// import NavBar from "../components/Header/NavbarTwo";

// const Blog = () => {
//   return (
//     <>
//       <NavBar />
//       <Breadcrumb title={"Blog"} />
//       <BlogGroup />
//       <FooterOne />
//     </>
//   );
// };

// export default Blog;

import React from "react";
import BlogPosts from "../components/blogs/BlogDetail";
import { Helmet } from "react-helmet";

function Blog() {
  return (
    <>
      <Helmet>
        <meta name="title" content="Latest Blogs Tech & RCM  - TechMatter (PVT) Ltd" />

        <meta
          name="description"
          content="Discover TechMatter's blog, featuring insightful articles, resources, and expert perspectives on IT, RCM marketing, and more services."
        />

        <link rel="canonical" href="https://techmatterglobal.com/blogs" />
        <title> Latest Blogs Tech & RCM  - TechMatter (PVT) Ltd</title>
      </Helmet>
      <BlogPosts />
    </>
  );
}

export default Blog;
