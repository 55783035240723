import React from "react";
import Mission from "../Career/Images/mission.png";
import Vision from "../Career/Images/vision.png";


const CareerAreaOne = () => {
  return (
    <>
      {/* career area start */}
      <div className="career-section pd-top-60 ">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-6">
              <div className="section-title text-center">
                <h2 className="title">
                  The Power Of <span>Software</span> The Simplicity Of Solutions
                </h2>
              </div>
            </div>
          </div>
          <div className="career-wrap-area">
            <nav>
              <div
                className="nav nav-tabs career-nav-tab"
                id="nav-tab"
                role="tablist"
              >
                <button
                  className="nav-link active"
                  id="nav-mission-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#nav-mission"
                  type="button"
                  role="tab"
                  aria-controls="nav-mission"
                  aria-selected="true"
                >
                  Mission
                </button>
                <button
                  className="nav-link"
                  id="nav-vision-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#nav-vision"
                  type="button"
                  role="tab"
                  aria-controls="nav-vision"
                  aria-selected="false"
                >
                  Vision
                </button>
              </div>
            </nav>
            <div className="tab-content" id="nav-tabContent">
              <div
                className="tab-pane fade show active"
                id="nav-mission"
                role="tabpanel"
                aria-labelledby="nav-mission-tab"
              >
                <div className="career-wrap">
                  <div className="row">
                    <div className="col-lg-5">
                      <img src={Mission} alt="Mission Image" />
                    </div>
                    <div className="col-lg-7">
                      <h3 className="mb-3">Our Mission </h3>
                      <p className="mb-3">
                        Our Mission as a provider of IT services is to give our
                        customers with solutions that help them meet their
                        business objectives in innovative and effective ways.
                        Maintaining the highest levels of professionalism and
                        honesty while using the most up-to-date tools and
                        methods is a big part of our goal to provide top-notch
                        service.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="tab-pane fade"
                id="nav-vision"
                role="tabpanel"
                aria-labelledby="nav-vision-tab"
              >
                <div className="career-wrap">
                  <div className="row">
                    <div className="col-lg-5">
                      <img src={Vision} alt="Vision Image" />
                    </div>
                    <div className="col-lg-7">
                      <h3 className="mb-3">Our Vision</h3>
                      <p className="mb-3">
                        Our Vision extends far beyond the realm of technology;
                        it encapsulates our commitment to shaping a future where
                        innovation serves as a conduit for positive change. As
                        we embark on this journey, our vision towards a future
                        defined by progress, collaboration, and ethical
                        leadership, we invite you to join us on this
                        transformative journey. Together, let us pioneer
                        tomorrow, one innovation at a time.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* career area start */}
    </>
  );
};

export default CareerAreaOne;
