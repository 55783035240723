import React from "react";
import { FaArrowRight } from "react-icons/fa";

function PrivacyPolicyDetail() {
  return (
    <>
      <div className="service-area pd-top-120 pd-bottom-90">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="blog-details-page-content">
                <div className="single-blog-inner mb-0">
                  <div className="details">
                  <p>
                    Our Privacy Policy explains how we use, collect, and protect the personal data collected through our website and IT services.
                    </p>
                    <h4>
                    Information We Collect: 
                    </h4>
                    <p>
                    When you contact us or use our services, we collect personal information (name, email, phone, company). Cookies and similar technologies on our website collect non-personal information (IP address, browsing behavior).
                    </p>
                    <h4>
                    How We Use Your Information:  
                    </h4>
                    <p>
                    We collect information to improve our services, communicate with you, process transactions, and personalize your experience. With your consent, we may also use your information for marketing purposes.
                    </p>
                    <h4>
                    Retention of Information:  
                    </h4>
                    <p>
                    We will retain your data only for the duration required to provide our IT and Marketing Services in compliance with legal obligations.
                    </p>
                    <h4>
                    Privacy Rights: 
                    </h4>
                    <p>
                    You can correct or delete any personal information and opt out of marketing communications by following email instructions.
                    </p>
                    <h4>
                    Changes to Privacy Policy: 
                    </h4>
                    <p>
                    We have the right to modify our Privacy Policy at any time. The updated policy will be accordingly posted on this page with the effective date. Please check back periodically for any changes.
                    </p>
                    
                    <h4>
                    Contact Us:  
                    </h4>
                    <p>
                    If you have any queries regarding our Privacy Policy, please email us at <a href="mailto:info@techmatterglobal.com">info@techmatterglobal.com</a>. By using Tech Matter’s services, you agree to this Privacy Policy.
                    </p>
                    
                    
                    
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default PrivacyPolicyDetail;
