import React from "react";
import FooterOne from "../components/Footer/FooterTwo";
import NavBar from "../components/Header/NavbarTwo";
import Breadcrumb from "../components/Breadcrumb/Breadcrumb";
import PrivacyPolicyDetail from "../components/PrivacyPolicyDetail";
import CounterAreaThree from "../components/Counter/CounterAreaThree";
import { Helmet } from "react-helmet";

function PrivacyPolicy() {
  return (
    <>
      <Helmet>
        <meta
          name="title"
          content="Privacy Policy - TechMatter (Pvt) Ltd"
        />
        <meta
          name="description"
          content=" Learn how TechMatter (Pvt) Ltd collects, uses, and protects your personal information. Our privacy policy outlines our commitment to keeping your data safe and secure."
        />
        <title>Privacy Policy - TechMatter (Pvt) Ltd</title>
      </Helmet>
      <NavBar />
      <Breadcrumb title={"Privacy Policy"} />
      <PrivacyPolicyDetail />
      <CounterAreaThree />
      <FooterOne />
    </>
  );
}

export default PrivacyPolicy;
