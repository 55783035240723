import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { ApolloClient, InMemoryCache, gql } from "@apollo/client";
import NavbarTwo from "../Header/NavbarTwo";
import Breadcrumb from "../Breadcrumb/Breadcrumb";
import FooterOne from "../Footer/FooterTwo";
import { Link } from "react-router-dom";
import createDOMPurify from "dompurify";

const DomPurify = createDOMPurify(window)

const client = new ApolloClient({
  uri: "https://techmatterglobal.com/blog/graphql",
  cache: new InMemoryCache(),
});

function getPostInnerText(htmlContent) {
  const tempElement = document.createElement('div');
  tempElement.innerHTML = htmlContent;
  return tempElement.innerText;
}

const BlogPostDetail = () => {
  const { slug } = useParams();
  const [post, setPost] = useState(null);
  const [loading, setLoading] = useState(true);
  const [otherPosts, setOtherPosts] = useState([]);
  // const [categories, setCategories] = useState([]);
  // const [tags, setTags] = useState([]);

  useEffect(() => {
    setLoading(true);
    client
      .query({
        query: gql`
          query GetPost($slug: String!) {
            postBy(slug: $slug) {
              id
              title
              content
              date
              excerpt 
              featuredImage {
                node {
                  sourceUrl
                }
              }
              categories {
                nodes {
                  name
                  slug
                }
              }
              tags {
                nodes {
                  name
                  slug
                }
              }
            }
            posts(first: 6) {
              nodes {
                id
                title
                slug
                featuredImage {
                  node {
                    sourceUrl
                  }
                }
              }
            }
          }
        `,
        variables: { slug },
      })
      .then((result) => {
        setPost(result.data.postBy);
        setOtherPosts(result.data.posts.nodes);
        // setCategories(result.data.postBy.categories.nodes);
        // setTags(result.data.postBy.tags.nodes);
        setLoading(false);
      })
      .catch((error) => {
        console.error(error);
        setLoading(false);
      });
  }, [slug]);



  return (
    <>
      <NavbarTwo />
      {post && <title>{post.title}</title>}
      {/* {post && <meta name="description" content={post.excerpt} />} */}
      {post && (
        <meta name="description" content={getPostInnerText(post.excerpt)} />
      )}
      {post && <Breadcrumb title={post.title} />}

      <div className="container pd-top-90 pd-bottom-90">
        <div className="row">
          <div className="col-md-9">
            <div className="blog-post-detail">
              {loading && <p>Loading...</p>}
              {!loading && post && (
                <div>
                  {post.featuredImage && post.featuredImage.node && (
                    <img
                      src={post.featuredImage.node.sourceUrl}
                      alt="Featured Image"
                      className="blog-post-image"
                    />
                  )}
                  <div
                    className="blog-post-content mt-4"
                    dangerouslySetInnerHTML={{ __html: DomPurify.sanitize(post.content) }}
                  />
                </div>
              )}
              {!loading && !post && <p>Blog post not found.</p>}
            </div>
          </div>
          <div className="col-md-3">
            <div className="other-blog-links">
              <h4 className="text-center">Other Blogs</h4>
              <ul style={{ listStyleType: "none" }}>
                {otherPosts.map((post) => (
                  <li key={post.id} style={{ marginBottom: "10px" }}>
                    <Link to={`/blogs/${post.slug}`}>
                      {post.featuredImage && post.featuredImage.node && (
                        <img
                          src={post.featuredImage.node.sourceUrl}
                          alt={post.title}
                        />
                      )}
                      {post.title}
                    </Link>
                  </li>
                ))}
              </ul>
            </div>
            {/* <div className="blog-categories">
              <h4 className="text-center">Categories</h4>
              <ul>
                {categories.map((category) => (
                  <p key={category.slug} className="blogCategoryName">{category.name}</p>
                ))}
              </ul>
            </div> */}
            {/* <div className="blog-tags">
              <h4 className="text-center">Tags</h4>
              <ul>
                {tags.map((tag) => (
                  <h6 key={tag.slug} className="blogsTagName">{tag.name}</h6>
                ))}
              </ul>
            </div> */}
          </div>
        </div>
      </div>
      <FooterOne />
    </>
  );
};

export default BlogPostDetail;