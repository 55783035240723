import React from "react";
import ServicesFaqImageOne from "./Images/services-faq-image-one.png";
import ServicesFaqImageTwo from "./Images/services-faq-image-two.png";


function ServicesFaqs() {
  return (
    <>
      <div
        className="faq-area faq-area-margin-top bg-cover pd-top-90 pd-bottom-110"
        //  style={{ backgroundImage: 'url("./assets/img/bg/3.png")' }}
      >
        <div className="container">
          <div className="row pd-top-120">
            <div
              className="col-xl-5 col-lg-6 col-md-8 order-lg-last"
              data-aos="fade-left"
              data-aos-delay="100"
              data-aos-duration="1500"
            >
              <div className="about-thumb-inner pt-lg-3">
                <img
                  className="main-img"
                  src={ServicesFaqImageOne}
                  alt="Services Faq Image One"
                />
                <img
                  className="animate-img-bottom-right top_image_bounce"
                  src={ServicesFaqImageTwo}
                  alt="Services Faq Image Two"
                />
              </div>
            </div>
            <div
              className="col-xl-7 col-lg-6"
              data-aos="fade-right"
              data-aos-delay="100"
              data-aos-duration="1500"
            >
              <div className="section-title mb-0 mt-4 mt-lg-0">
                <h2 className="title">
                  <span> Any Queries? </span> We are here to assist
                </h2>
                <p className="content">
                  Contact Tech Matter to schedule an IT consultation. We'll
                  evaluate your infrastructure, discuss your needs, and suggest
                  personalized solutions.
                </p>
              </div>
              <div
                className="accordion accordion-inner style-2 accordion-icon-left mt-3"
                id="accordionExample"
              >
                <div className="accordion-item">
                  <h2 className="accordion-header" id="headingOne">
                    <button
                      className="accordion-button"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseOne"
                      aria-expanded="true"
                      aria-controls="collapseOne"
                    >
                      What services does Tech Matter offer?
                    </button>
                  </h2>
                  <div
                    id="collapseOne"
                    className="accordion-collapse collapse show"
                    aria-labelledby="headingOne"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body">
                      Tech Matter specializes in managed IT services and
                      Software Development. Other services include Revenue Cycle
                      Management, Healthcare Staff Augmentation, EHR, and
                      Enterprise IT Solutions.
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header" id="headingTwo">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseTwo"
                      aria-expanded="false"
                      aria-controls="collapseTwo"
                    >
                      How can Tech Matter help my business grow?
                    </button>
                  </h2>
                  <div
                    id="collapseTwo"
                    className="accordion-collapse collapse"
                    aria-labelledby="headingTwo"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body">
                      Tech Matter enhances your online presence, streamlines IT
                      operations, and optimizes digital marketing. Get a new
                      website, custom software, or marketing strategy guidance.
                      Thrive in the digital landscape with Tech Matter.
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header" id="headingThree">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseThree"
                      aria-expanded="false"
                      aria-controls="collapseThree"
                    >
                      What sets Tech Matter apart from other IT service
                      providers?
                    </button>
                  </h2>
                  <div
                    id="collapseThree"
                    className="accordion-collapse collapse"
                    aria-labelledby="headingThree"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body">
                      At Tech Matter, we are committed to innovation, quality,
                      and customer satisfaction. Our skilled professionals
                      leverage technology to drive business success, exceeding
                      client expectations with effective and reliable solutions.
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ServicesFaqs;
