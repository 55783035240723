import React from "react";
import "./style.css";
import { Link } from "react-router-dom";

export default function PageNotFoundDetails() {
  return (
    <>
      <div class="section container">
        <h1 class="error">404</h1>
        <div class="page">
          Ooops!!! The page you are looking for is not found
        </div>
        <Link to="/" class="back-home">
          Back to home
        </Link>
      </div>
    </>
  );
}
