import React from "react";
import ContactMain from "../components/Contact/ContactMain";
import FooterOne from "../components/Footer/FooterTwo";
import NavBar from "../components/Header/NavbarTwo";
import { Helmet } from "react-helmet";

const Contact = () => {
  return (
    <>
      <Helmet>
        <meta name="title" content="Contact IT & RCM Expert - TechMatter (PVT) Ltd" />

        <meta
          name="description"
          content="Get in touch with Tech Matter, your trusted partner for comprehensive IT management, innovative solutions, RCM services and more. "
        />
        <link rel="canonical" href="https://techmatterglobal.com/contact" />
        <title>Contact IT & RCM Expert - TechMatter (PVT) Ltd</title>
      </Helmet>
      <NavBar />
      <ContactMain />
      <FooterOne />
    </>
  );
};

export default Contact;
