import React from "react";
import AboutAreaOne from "../components/About/AboutAreaTwo";
import BannerOne from "../components/BannerTwo";
import BlogAreaOne from "../components/BlogAreaOne";
import ContactAreaOne from "../components/ContactAreaOne";
import CounterAreaOne from "../components/CounterAreaTwo";
import FaqAreaOne from "../components/FaqAreaOne";
import FooterOne from "../components/Footer/FooterOne";
import NavBar from "../components/Header/NavbarTwo";
import WorkProcessOne from "../components/WorkProcessOne";
import ProjectAreaOne from "../components/ProjectAreaOne";
import { Helmet } from "react-helmet";

const HomeOne = () => {
  const schemaMarkup = `
  {
    "@context": "http://schema.org",
    "@type": "WebPage",
    "name": "TechMatter Global - IT Solutions for Modern Businesses",
    "description": "TechMatter Global provides cutting-edge IT solutions to streamline your business operations. From cybersecurity to comprehensive IT support, we have you covered.",
    "url": "https://techmatterglobal.com/",
    "publisher": {
      "@type": "Organization",
      "name": "TechMatter (Pvt) Ltd",
      "url": "https://techmatterglobal.com/",
      "logo": "https://techmatterglobal.com/static/media/techmatter-logo.18ba9962b358c5d14134.webp",
      "description": "TechMatter (Pvt) Ltd is a leading Top-tier IT managed services provider. Specializing in security, helpdesk, and comprehensive IT support solutions.",
      "telephone": "(021) 37447307",
      "email": "info@techmatterglobal.com",
      "sameAs": [
        "https://www.linkedin.com/company/techmatter-pvt-ltd/",
        "https://twitter.com/techmatterpvt",
        "https://www.facebook.com/techmatterglobal/",
        "https://www.instagram.com/techmatterglobal/"
      ],
      "address": {
        "@type": "PostalAddress",
        "streetAddress": "194-N, Block 2 PECHS",
        "addressLocality": "Karachi",
        "addressRegion": "Sindh",
        "addressCountry": "Pakistan"
      }
    }
  }`;
  return (
    <>
      <Helmet>
        <meta
          name="title"
          content="Best Software Outsourcing & IT Services Company - TechMatter (PVT) Ltd"
        />
        <meta
          name="description"
          content="Experience unmatched proficiency and flawless solutions with our leading outsourcing IT services firm. Enhance your projects with top-tier expertise. 
          "
        />
        <meta
          name="keywords"
          content="Managed IT Services Company, managed equipment services"
        />
        <link rel="canonical" href="https://techmatterglobal.com" />
        <title>Best Software Outsourcing & IT Services Company - TechMatter (PVT) Ltd</title>
        <script type="application/ld+json">{schemaMarkup}</script>
        
      </Helmet>
      <NavBar />
      <BannerOne />
      <WorkProcessOne />
      <AboutAreaOne />
      <ProjectAreaOne />
      <FaqAreaOne />
      <CounterAreaOne />
      <ContactAreaOne />
      <BlogAreaOne />
      <FooterOne />
    </>
  );
};

export default HomeOne;
