import React from "react";
import calUSA from './Images/USA.png'
import pakis from './Images/Pakistan.png'
import UaeFlag from './Images/UAE-p.jpg'
import ukFlag from './Images/UK.png'

const ContactInfo = () => {
    return (
        <>
            {/*==================== Work Process One start ====================*/}

            <div className='work-process-area pd-top-40 pd-bottom-40 work-process-bg'>
                <div className='container'>
                    {/* <div className='section-title text-center'>
            <h6 className='sub-title'>OUR SIMPLE PROCESS</h6>
            <h2 className='title'>
              World <span>Best Step</span> Our It Process
            </h2>
          </div> */}
                    <div className='row justify-content-center'>
                        <div className='col-xl-4 col-md-6'>
                            <div className='single-work-process-inner single-work-process-inner-01 address-work-process-inner'>
                                <div className='thumb mb-3'>
                                    <img src={calUSA} alt='USA Flag' />
                                </div>
                                <div className='details infoDeatils'>
                                    {/* <p className='address-paragraph'>433 Walnut Ct Pittsburgh, PA 15237, USA</p> */}

                                    <h5 className="mb-2">California Office</h5>
                                    <p className='content'>
                                        100 N Brand Blvd Suite 522, <br /> Glendale, CA 91203, USA
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className='col-xl-4 col-md-6'>
                            <div className='single-work-process-inner single-work-process-inner-01 address-work-process-inner'>
                                <div className='thumb mb-3'>
                                    <img src={calUSA} alt='USA Flag' />
                                </div>
                                <div className='details infoDeatils'>
                                    {/* <p className='address-paragraph'>433 Walnut Ct Pittsburgh, PA 15237, USA</p> */}

                                    <h5 className="mb-2">Pennsylvania Office</h5>
                                    <p className='content'>
                                        433 Walnut Ct Pittsburgh,<br /> PA 15237, USA
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className='col-xl-4 col-md-6'>
                            <div className='single-work-process-inner single-work-process-inner-01 address-work-process-inner'>
                                <div className='thumb mb-3'>
                                    <img src={pakis} alt='Pakistan Flag' />
                                </div>
                                <div className='details infoDeatils'>
                                    {/* <p className='address-paragraph'>433 Walnut Ct Pittsburgh, PA 15237, USA</p> */}

                                    <h5 className="mb-2">Karachi Office</h5>
                                    <p className='content'>
                                        194-N, P.E.C.H.S Block 2, 75350 <br /> Karachi, PK
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className='col-xl-4 col-md-6'>
                            <div className='single-work-process-inner single-work-process-inner-01 address-work-process-inner islamabadOffice'>
                                <div className='thumb mb-3'>
                                    <img src={pakis} alt='Pakistan Flag' />
                                </div>
                                <div className='details infoDeatils'>
                                    {/* <p className='address-paragraph'>433 Walnut Ct Pittsburgh, PA 15237, USA</p> */}

                                    <h5 className="mb-2">Islamabad Office</h5>
                                    <p className='content'>
                                        Markaz G-8, Islamabad, PK
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className='col-xl-4 col-md-6'>
                            <div className='single-work-process-inner single-work-process-inner-01 address-work-process-inner'>
                                <div className='thumb mb-3'>
                                    <img src={UaeFlag} alt='Dubai Flag' />
                                </div>
                                <div className='details infoDeatils'>
                                    {/* <p className='address-paragraph'>433 Walnut Ct Pittsburgh, PA 15237, USA</p> */}
                                    <h5 className="mb-2">Dubai Office
                                    </h5>
                                    <p className='content'>Office 1908, 19th floor The Binary Tower, Business Bay, UAE</p>
                                </div>
                            </div>
                        </div>
                        <div className='col-xl-4 col-md-6'>
                            <div className='single-work-process-inner single-work-process-inner-01 address-work-process-inner'>
                                <div className='thumb mb-3'>
                                    <img src={ukFlag} alt='UK Flag' />
                                </div>
                                <div className='details infoDeatils'>
                                    {/* <p className='address-paragraph'>433 Walnut Ct Pittsburgh, PA 15237, USA</p> */}

                                    <h5 className="mb-2">London Office
                                    </h5>
                                    <p className='content'>
                                        F-10 Leaf House, Catherine Place,<br />  Harrow, HA12JW,UK
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* ==================== Work Process One end ====================*/}
        </>
    );
};

export default ContactInfo;
